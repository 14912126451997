import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom';

// Installed
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { Alert, CircularProgress, FormLabel, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { Archive, Check, ChecklistRtl, Delete, ForwardToInbox, Lock, LockOpen, SendSharp } from '@mui/icons-material';
import { BarChart } from '@mui/x-charts';

// Components
import LinearLoading from '../../components/LinearLoading';
import Modal from '../../components/Modal';
import Container from '../../components/Container';
import Countdown from '../../components/Countdown';
import DropdownList from '../../components/DropdownList';

// Functions
import { ErrorHandle } from '../../functions/ErrorHandle';
import { CheckNumber } from '../../functions/CheckNumber';

// Services
import ApiRequest from '../../services/ApiRequest';

// Css
import '../../assets/css/survey.css';

function SurveyView({ hidden, api, update }) {
    SurveyView.displayName = "SurveyView";

    const { id, email, send } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [survey, setSurvey] = useState(null);
    const [action, setAction] = useState();
    const [error, setError] = useState(null);
    const [diagrams, setDiagrams] = useState([]);
    const [boxView, setBoxView] = useState("analysis");
    const [freeTexts, setFreeTexts] = useState([]);
    const [statistics, setStatistics] = useState();
    const [request, setRequest] = useState();

    useEffect(() => {
        if (!!id || update)
            getSurvey();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, update])

    const getSurvey = async () => {
        await ApiRequest(`${api}/${id}`).then(res => {
            setLoading(false);

            if (res.status === 200 && !res?.data?.error) {
                const { survey, diagrams, freeTexts, statistics } = res.data;

                if (!survey || !diagrams)
                    setMessage("Inga undersökningsdata tillgängliga.")

                setSurvey(survey);
                setDiagrams(diagrams);
                setFreeTexts(freeTexts);
                setStatistics(statistics);
                document.title = `Översikt | ${survey?.name}`;
            } else if (res?.data?.error)
                setError(res.data);

        }, error => {
            setLoading(false);
            setError(ErrorHandle(error, navigate));
        });
    }

    const clickHandle = async () => {
        const obj = action;
        setAction();
        setRequest();
        if (!!obj.url)
            obj.run(obj.url);
        else
            obj.run();
    }

    const sendRemindMail = async () => {
        const id = survey?.id;
        await ApiRequest(`survey/remind/mails/${id}`).then(res => {
            if (res.status === 200 && !res?.data) {
                setRequest("success");
                setTimeout(() => {
                    setRequest();
                }, 1000)
            } else {
                setRequest();
                setError(res?.data);
            }
        }, error => setError(ErrorHandle(error, navigate)));
    }

    const cancelHandle = async (url) => {
        const id = survey?.id;

        await ApiRequest(`survey/${url ?? ""}${id}`, "delete").then(res => {
            if (res?.data === null)
                navigate("/");
            else
                setError(res?.data);
        }, error => setError(ErrorHandle(error, navigate)));
    }

    const lockHandle = async () => {
        await ApiRequest(`survey/lock/${survey?.id}`, "put", survey).then(res => {
            setRequest();
            if (res.status === 200 && !res?.data)
                getSurvey();
            else
                setError(res?.data);
        }, error => setError(ErrorHandle(error, navigate)));
    }

    const closeModal = () => {
        setAction();
        setRequest();
    }

    const reset = () => {
        if (error?.error)
            navigate(-1);
        setError(null)
    }

    const switchBoxView = (e, value) => {
        setBoxView(value);
    }

    const unanswered = !survey ? 0 : survey?.receivers?.participants?.length - survey?.receivers?.responded?.length;
    const buttons = <>
        {(!hidden && !email && !send) && <>

            {/* Send reminder email */}
            {unanswered > 0 && <Tooltip title="Skicka påminnelse." classes={{
                tooltip: "tooltip-white",
                arrow: "tooltip-arrow-white"
            }} arrow>
                <IconButton onClick={() => {
                    setAction({ run: sendRemindMail, msg: `Skicka påminnelse till ${unanswered} deltagare?` });
                    setRequest("remind");
                }}>
                    {request === "remind" ? <CircularProgress size={23} color='inherit' />
                        : (request === "success" ? <Check color="success" /> : <ForwardToInbox color="warning" />)}
                </IconButton>
            </Tooltip>}

            {/* Send to further participants */}
            <Tooltip title="Skicka till flera" classes={{
                tooltip: "tooltip-white",
                arrow: "tooltip-arrow-white"
            }} arrow>
                <IconButton onClick={() => navigate("send")}>
                    <SendSharp size={23} color='primary' />
                </IconButton>
            </Tooltip>

            {/* Send to archive */}
            {survey?.closed && <Tooltip title="Skicka till arkiv." classes={{
                tooltip: "tooltip-white",
                arrow: "tooltip-arrow-white"
            }} arrow>
                <IconButton onClick={() => setAction({ run: cancelHandle, url: `move/`, msg: "Skicka till arkiv?" })}>
                    <Archive color="info" />
                </IconButton>
            </Tooltip>}

            {/*  Close or open survey */}
            <IconButton onClick={() => {
                setAction({ run: lockHandle, msg: `${!survey?.closed ? "Stänga" : "Öppna"}?` });
                setRequest("lock");
            }}>
                {request === "lock" ? <CircularProgress size={23} color='inherit' />
                    : (survey?.closed ? <Lock color="error" /> : <LockOpen color="success" />)}
            </IconButton>
        </>}

        {/* Delete survey */}
        {!email && <IconButton onClick={() => setAction({ run: cancelHandle, msg: "Radera undersökningen?" })}>
            <Delete color='error' />
        </IconButton>}
    </>

    const result = CheckNumber(survey?.receivers?.result);
    const filled = (result === 0 || result === 100);

    if (message)
        return <Modal msg="Fel!" content={message} close={() => navigate(-1)} />;

    return (<div className="view-form-wrapper view-wrapper fade-in w-100">

        {/* Loading */}
        {loading && <LinearLoading msg="enkät laddas upp ..." color="success" size={30} />}

        {/* Content */}
        {!loading && <Container
            label={survey?.name ?? "Fel"}
            hidden={true}
            buttons={(!send && !email) ? buttons : null}>

            {/* Error*/}
            {error && <Alert className='d-row' variant='standard' severity='error' color="error" onClose={reset}>
                <p dangerouslySetInnerHTML={{ __html: error?.msg ?? error }}></p>
            </Alert>}

            {/* Charts */}
            {(!!survey && !email && !send) && <div className='survey-box d-row jc-between'>

                {/* Information */}
                <div className='survey-flex-box'>
                    <List className="w100">
                        <ListItem className='d-row jc-start' secondaryAction={survey?.receivers?.participants.length}>
                            <ListItemText primary="Deltagare" />
                        </ListItem>
                        <ListItem className='d-row jc-start' secondaryAction={survey?.receivers?.responded.length}>
                            <ListItemText primary="Besvarade enkätter" />
                        </ListItem>
                        <ListItem className='d-row jc-start' secondaryAction={survey?.questionsCount}>
                            <ListItemText primary="Frågor i enkät" />
                        </ListItem>
                        <ListItem className='d-row jc-start' secondaryAction={survey?.supplierName}>
                            <ListItemText primary="Leverantör" />
                        </ListItem>
                        <ListItem className='d-row jc-start' secondaryAction={survey?.categoryName}>
                            <ListItemText primary="Kategori" />
                        </ListItem>
                        <ListItem className='d-row jc-start' secondaryAction={(!survey?.closed && !hidden) ? <span style={{ color: "#18d107" }}>Öppen</span> : <span style={{ color: "#a50029" }}>Stängd</span>}>
                            <ListItemText primary="Status" />
                        </ListItem>
                    </List>
                </div>

                {/* A box to view the full survey results in the form of a pie chart and a deadline timer. */}
                <div className='survey-flex-box d-column ai-start'>
                    <PieChart
                        series={[
                            {
                                arcLabel: (i) => `${i.value} %`,
                                arcLabelMinAngle: 45,
                                data: [
                                    { id: "0", value: parseFloat(result), label: ` Färdig ${result}%`, color: "#18d107" },
                                    { id: "1", value: parseFloat(100 - result), label: ` Återstående ${100 - result}%`, color: "#a50029" },
                                ],
                                highlightScope: { faded: 'global', highlighted: 'item' },
                                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                innerRadius: filled ? 60 : 35,
                                outerRadius: 100,
                                paddingAngle: filled ? 0 : 1,
                                cornerRadius: filled ? 0 : 3,
                                startAngle: 0,
                                endAngle: 360,
                                cx: 10,
                                // cy: 150,
                            },
                        ]}
                        sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontWeight: 'bold'
                            }
                        }}
                        height={250}
                        margin={{ left: 10, right: -100 }}
                    />
                    <List className="w100">
                        {!hidden && <ListItem className='d-row jc-start' secondaryAction={survey?.closed ? survey?.deadlineTime : <Countdown deadline={survey?.deadlineSeconds} color='#a50029' />}>
                            <ListItemText primary={survey?.closed ? "Stängd" : "Återstående tid"} />
                        </ListItem>}
                        <ListItem className='d-row jc-start' secondaryAction={survey?.dateString}>
                            <ListItemText primary="Skickad" />
                        </ListItem>
                        {hidden && <ListItem className='d-row jc-start' secondaryAction={survey?.archiveDate}>
                            <ListItemText primary="Arkiveras" />
                        </ListItem>}
                    </List>
                </div>

            </div>}

            {/* List of participants */}
            {(!error && !send && !!survey?.receivers) &&
                <div className={`survey-dropdown-box${email ? "-view" : ""} d-column`} >
                    {!!email && <InputLabel className="survey-label">Deltagre</InputLabel>}
                    <DropdownList
                        label={email ? email : "Deltagre"}
                        icon={<ChecklistRtl />}
                        list={survey?.receivers?.participants}
                        selected={survey?.receivers?.responded}
                        removable="email"
                        removeDisabled={survey?.receivers?.participants?.length === 1}
                        api={`survey/remove/receiver/${id}`}
                        dropdown={false}
                        onClickHandle={(value) => navigate(`responses/${value}`)}
                    />
                </div>}

            {/* Point statistics */}
            {(!email && !!statistics && !send) && <div className='survey-box'>
                <div className="diagrams-wrapper">
                    <InputLabel className='w100'><span style={{ fontWeight: 600 }}>Poängs result</span></InputLabel>

                    <BarChart
                        xAxis={[{ scaleType: 'band', data: [` Negativa poängs: ${statistics?.negative ?? 0}`, ` Positiva poängs: ${statistics?.positive ?? 0}`, ` Total Poängs: ${statistics?.total ?? 0}`] }]}
                        series={
                            [
                                { data: [statistics?.negative, 0, 0], label: "Negativa\t", color: "#e06411", type: "bar", stack: "total" },
                                { data: [0, statistics?.positive, 0], label: "Positive\t", color: "#16f08a", type: "bar", stack: "total" },
                                { data: [0, 0, statistics?.total], label: "Total poäng", color: "#0047a5", type: "bar", stack: "total" }
                            ]
                        }
                        // layout='horizontal'
                        tooltip={{ trigger: 'item' }}
                        height={300}
                        // margin={{ left: 30, right: 10 }}
                        slotProps={{
                            legend: {
                                direction: 'row',
                                position: { vertical: 'top', horizontal: 'middle' },
                                padding: 0,
                                markGap: 10,
                                itemGap: 50
                            }
                        }}
                    />
                </div>
            </div>}

            {/* If there is no answer */}
            {(survey?.receivers?.responded?.length === 0 && !send && !email) && <div className="w-100" style={{ margin: "12px", marginTop: "-30px" }}>
                <Alert severity='info' color="info">
                    Det finns inga svar än ...
                </Alert>
            </div>}

            {/* Diagrams list */}
            {(!email && !send) && diagrams?.map((item, index) => {
                if (item?.charts?.length === 0) 
                    return null;

                const res = item?.result;
                const points = res?.points;
                const freeResponses = freeTexts?.filter(x => x.keyword === item?.keyword) ?? [];
                return <div className='survey-box' key={index}>
                    {/* Label */}
                    <InputLabel className='d-row ai-start jc-start w100'>
                        <span style={{ fontWeight: 600, marginRight: "7px" }}>Fråga: </span>
                        <span dangerouslySetInnerHTML={{__html: item?.name}}></span>
                    </InputLabel>

                    {/* Diagram container */}
                    <div className='diagrams-wrapper d-column'>
                        <div className={`diagram-box${!!res ? " margin-border" : ""}`}>
                            <FormLabel>Svar</FormLabel>

                            <PieChart
                                series={[
                                    {
                                        arcLabel: (i) => `${i.value}`,
                                        arcLabelMinAngle: 45,
                                        data: item?.charts,
                                        highlightScope: { faded: 'global', highlighted: 'item' },
                                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                        innerRadius: 35,
                                        outerRadius: 100,
                                        paddingAngle: 1,
                                        cornerRadius: 3,
                                        cx: 70,
                                        cy: 110
                                    },
                                ]}
                                sx={{
                                    [`& .${pieArcLabelClasses.root}`]: {
                                        fill: 'white',
                                        fontWeight: 'bold'
                                    },
                                    "& .MuiChartsLegend-column ": {
                                        width: "300px !important",
                                        marginRight: "0"
                                    },
                                    "& .MuiChartsLegend-series text": {
                                        fontSize: "12px !important",
                                        fontFamily: "monospace !important",
                                        letterSpacing: "1px !important",
                                        lineHeight: "30px !important"
                                    }
                                }}
                                slotProps={{
                                    legend: {
                                        direction: 'column',
                                        position: { vertical: 'middle', horizontal: 'middle' },
                                        padding: 0,
                                        markGap: 10,
                                        itemGap: 15,
                                        labelStyle: {
                                            fontSize: 10,
                                            lineBreak: "anywhere"
                                        }
                                    },
                                }}
                                height={250}
                                margin={{ left: 70, right: 50 }}
                            />
                        </div>

                        {/* View diagram if question has positive reply choice */}
                        {(!!res || freeResponses?.length > 0) &&
                            <div className='diagram-box free-response d-column ai-start jc-start'>
                                <ToggleButtonGroup
                                    value={boxView}
                                    exclusive
                                    onChange={switchBoxView}
                                    aria-label="box menu"
                                    style={{ marginBottom: "30px" }}
                                >
                                    {!!res && <ToggleButton value={`analysis`} aria-label="left aligned">
                                        Analys av svar
                                    </ToggleButton>}
                                    {freeResponses?.length > 0 && <ToggleButton value={`answers${index}`} aria-label="centered">
                                        Svar med fri text
                                    </ToggleButton>}
                                </ToggleButtonGroup>

                                {/* Statistics */}
                                {(boxView !== `answers${index}` && !!res) && <div className='d-column w-100 fade-in'>
                                    <FormLabel className="w-100">Positiv svar är: <span>{item.positive}</span></FormLabel>

                                    <div className="d-row jc-between w-100">
                                        <BarChart xAxis={[{ scaleType: 'band', data: ["Positive", "Negative"] }]}
                                            series={
                                                [
                                                    { data: [res?.positive, 0], label: ` Positiv: ${res?.positive}%\t\t`, color: "#18d107", type: "bar", stack: "total" },
                                                    { data: [0, res?.negative], label: ` Negativ: ${res?.negative}%\t\t`, color: "#a50029", type: "bar", stack: "total" }
                                                ]
                                            }
                                            // layout='horizontal'
                                            tooltip={{ trigger: 'item' }}
                                            height={300}
                                            margin={{ left: 30, right: 10 }}
                                        />

                                        {!!points && <BarChart
                                            xAxis={[{ scaleType: 'band', data: [` Negativa poängs: ${points?.negative ?? 0}`, ` Positiva poängs: ${points?.positive ?? 0}`, ` Total Poängs: ${points?.total ?? 0}`] }]}
                                            series={
                                                [
                                                    { data: [points?.negative, 0, 0], label: "Negativa\t", color: "#e06411", type: "bar", stack: "total" },
                                                    { data: [0, points?.positive, 0], label: "Positive\t", color: "#16f08a", type: "bar", stack: "total" },
                                                    { data: [0, 0, points?.total], label: "Total poäng", color: "#0047a5", type: "bar", stack: "total" }
                                                ]
                                            }
                                            // layout='horizontal'
                                            tooltip={{ trigger: 'item' }}
                                            height={300}
                                            margin={{ left: 30, right: 10 }}
                                        />}
                                    </div>
                                </div>}

                                {/* View free texts */}
                                {boxView === `answers${index}` && <div className='d-column w-100 jc-start fade-in'>
                                    <List className='w-100'>
                                        {freeResponses?.map((x, ind) => {
                                            return <ListItem className='d-row box-list-li-border' key={ind}>
                                                <ListItemIcon>
                                                    {ind + 1}.
                                                </ListItemIcon>
                                                <ListItemText primary={x.text} secondary={x?.email} />
                                            </ListItem>
                                        })}
                                    </List>
                                </div>}
                            </div>}

                    </div>
                </div>
            })}

            {email && <Outlet context={{ email, surveyId: id, navigate }} />}
            {send && <Outlet context={{ id, navigate }} />}
        </Container>}

        {/* Modal */}
        {!!action && <Modal
            buttonValue="Ja"
            msg={survey?.name}
            content={action?.msg}
            closeButtonValue="Avbryta"
            clickHandle={clickHandle}
            close={closeModal} />}
    </div >
    )
}

export default SurveyView