export function ErrorHandle(error, navigate) {
    
    if (error?.response?.status === 401)
        navigate("/session/expired");

    let msg = error?.msg ?? `Något har gått snett.<br/>Fel: ${typeof error === "object" ? error?.message : error}`;

    console.warn(msg);
    
    return {
        color: "error",
        msg: msg
    }
}