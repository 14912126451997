
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from 'lodash';

// Installed
import { Alert, AlertTitle } from "@mui/material";

// Components
import Response from "./Response";
import FormButtons from "./FormButtons";
import Loading from "./Loading";
import FormFields from "./FormFields";

// Functions
import { ErrorHandle } from "../functions/ErrorHandle";

// Services
import ApiRequest from "../services/ApiRequest"

// Css
import '../assets/css/form.css';

function FormPost({ api, inputs, resendModel, confirmation, model, disabled, button, numbers, classes, hideLoading, handleResponse }) {
    FormPost.displayName = "Form";

    const [loading, setLoading] = useState(false);
    const [initData, setInitData] = useState(null);
    const [formData, setFormData] = useState({});
    const [response, setResponse] = useState(null);
    const [fields, setFields] = useState(null);
    const [required, setRequired] = useState([]);
    const [changed, setChanged] = useState(false);
    const [warning, setWarning] = useState(false);

    const { id, paramId, resend } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setWarning(false);
        if (!!inputs)
            onBeginning(inputs);
        else if (!!id || !!paramId) {

            async function getInputs() {
                await ApiRequest(`form/questions/${id}`).then(res => {
                    onBeginning(res.data);
                    if (!res.data)
                        setWarning(true);
                })
            }

            if (resendModel) {
                setFormData(resendModel);
                return;
            } else
                getInputs();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputs, id, paramId, resend])

    const onBeginning = (list) => {
        let data = {};
        if (resendModel)
            data = resendModel;
        else if (list != null && list.length > 0) {
            // Change name of each input
            list?.forEach(item => {

                const ip = item?.iParams;
                data[item?.name] = item?.type === "checkbox" ? (!ip?.variants ? false : [])
                    : ((item?.type !== "radio" && ip?.variants?.length > 1 && ip?.parameters?.indexOf("multiple") > -1) ? [] : "");
            })
        }

        setRequired(list?.filter(x => !!x.iParams && !!x?.iParams?.parameters
            && x?.iParams?.parameters.indexOf("required") > -1).map(x => x?.name));
        setFormData(data);
        setInitData(JSON.stringify(data));
        setLoading(false);
        setFields(list);
    }

    const onChange = (data) => {
        if (!data || !initData) return;

        let obj = JSON.parse(initData ?? "[]");
        let original = true
      
        Object.keys(data).forEach(key => {
            if (original && obj[key] !== undefined) {
                original = (data[key] === 0 && obj[key] === "")
                    || (data[key] === obj[key]) || _.isEqual(data[key], obj[key]);
            }
        })

        setChanged(!original);
        setFormData(data);
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!changed)
            return;

        let obj = formData;

        let reqFields = required;
        // If form data needs send like as string
        if (!!model) {
            const responses = [];
            inputs?.forEach(inp => {
                const ip = inp?.iParams;

                // Check was this field connected to other or not
                if (!!ip?.connectedTo) {
                    const conn = ip.connectedTo;
                    let answered = (conn?.value === formData[conn?.name] || conn?.value?.indexOf(formData[conn?.name]) > -1);
                    if (!answered && required.indexOf(inp.name) > -1)
                        reqFields = reqFields.filter(x => x !== inp.name);
                }

                const pointsBased = ip?.pointsVariants?.length > 0;

                if (Object.keys(obj).indexOf(inp.name) > -1) {

                    let variants = ip?.variants ?? ip?.pointsVariants;
                    let value = obj[inp.name];
                    if (Array.isArray(value) && value.length === 0)
                        value = null;
                    else if ((pointsBased || inp.type === "slider") && (value === "" || value === null))
                        value = parseInt(variants[0])
                    let res = {
                        id: inp.id,
                        name: inp.label,
                        keyword: inp.name,
                        diagram: ip?.parameters?.indexOf("diagram") > -1,
                        variants: variants,
                        pointsBased: pointsBased,
                        value: value,
                        type: inp.type,
                        answered: value !== null,
                        participant: obj?.email,
                        other: !!obj?.other ? (obj?.other[inp.name] ?? "") : null,
                        freeText: !!obj?.freeTexts ? obj?.freeTexts[inp.name] : null
                    }

                    responses.push(res);
                }
            })

            model.resendValue = JSON.stringify(obj);
            model.responseJson = JSON.stringify(responses);
            obj = model;
        }


        if (e.nativeEvent.submitter.name === "save")
            obj.saved = true;
        else if(!!obj.saved)
            obj.saved = false;
        else {
            // Check is all required has answer
            let requiredList = [];
            reqFields.forEach(name => {
                if (!formData[name] || (formData[name].length === 0 && !formData.other[name]))
                    requiredList.push(`&nbsp;&nbsp;&nbsp;- ${fields?.find(x => x.name === name)?.label}`);
            })

            if (requiredList.length > 0) {
                setResponse({
                    error: true,
                    msg: "Obligatoriska fält är ej ifyllda.<br/><br/>" + requiredList.join("<br/>")
                })
                return;
            }
        }

        setLoading(true);
        await ApiRequest(api, (id || paramId || resend) ? "put" : "post", obj).then(response => {
            const res = response.data;
            setLoading(false);

            // If it is login form
            if (response?.status === 200 && (!res || (!res?.error && !res?.color)))
                setResponse({ msg: "Skickad!" })
            else
                setResponse(res);

            if (!!res?.log)
                console.warn(res);

            if (!!handleResponse)
                handleResponse(res);
        }).catch(error => {
            setLoading(false);
            setResponse(ErrorHandle(error, navigate));
        });
    }

    const reset = () => {
        setFormData(JSON.parse(initData));
        resetResponse();
    }

    const resetResponse = () => {
        setResponse(null);
        setChanged(false);
    }

    const res = <Response res={response} reset={resetResponse} />;

    // If loading
    if (loading && !disabled && !hideLoading)
        return <Loading size={40} msg="Ditt svar skickas ..." color="primary" style={{ height: "100%", minHeight: "50vh" }} />;

    // If success response
    if (response?.res === "success")
        return res;

    return (
        <form className={`d-column${(loading && !response) ? " disabled" : ""}${classes ?? ""} fade-in`}
            onSubmit={onSubmit}>

            {/*  Response */}
            {!!response && res}

            {/* Loading */}
            {(fields?.length === 0 && loading) && <Loading color="primary" size={30} style={{ height: "100%", minHeight: "50vh" }} msg="Data hämtas ..." />}

            {/* If for has no fields */}
            {(warning && !loading) && <Alert severity="warning" color="warning" className="view-form-content w-100">
                <AlertTitle>Varning!</AlertTitle>
                Enkät har inga frågor.
            </Alert>}

            {/* Form inputs */}
            {fields?.length > 0 && <FormFields
                inputs={fields}
                initData={formData}
                response={response}
                disabled={loading}
                numbers={numbers}
                onFormChange={onChange}
            />}

            {/* Buttons */}
            {(!disabled && !response) && <FormButtons
                confirmation={confirmation}
                disabled={loading || !changed}
                loading={loading}
                value="Skicka"
                reset={reset}>
                {button}
            </FormButtons>}
        </form>
    );
}

export default FormPost;