import { useEffect, useRef, useState } from "react";
import _ from "lodash";

// Installed
import {
  ChangeCircleOutlined, CheckBox, CheckBoxOutlineBlank, Close, Done, Edit,
  KeyboardReturn, List, QuestionMarkOutlined, RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Alert, Box, Button, Checkbox, FormControlLabel, FormGroup, FormLabel,
  IconButton, InputLabel, Select, Switch, TextField, MenuItem, FormControl
} from "@mui/material";

// Components
import FormButtons from "./FormButtons";
import DropdownList from "./DropdownList";
import ApiRequest from "../services/ApiRequest";
import Loading from "./Loading";
import FormFields from "./FormFields";

// Json
import json from "../assets/json/inputs.json";

const warnings = [
  "Min 2 och max 10 varianter är tillåtna per diagrammodell.",
  "En liknande {msg} finns redan i undersöknings formuläret.",
  "Obligatoriska fält måste fyllas i. {msg}",
];

function FormBuild({ item, name, reset, editIndex, questions, handleForm }) {
  FormBuild.displayName = "Form Building";

  const editing = !!editIndex;
  const defVariant = { label: "", value: 0 };
  const questionParams = json?.form.find((x) => x.type === item?.type);
  const buildParams = json?.questionParams.find((x) => x.type === item?.type);

  const [question, setQuestion] = useState(item);
  const [iParams, setIParams] = useState(item?.iParams);

  const [param, setParam] = useState("");
  const [swapIndex, setSwapIndex] = useState(null);
  const [error, setError] = useState(null);
  const [repeatedName, setRepeated] = useState();
  const [warning, setWarning] = useState();
  const [delay, setDelay] = useState(" delay");
  const [currentInputs, setCurrentsInputs] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [selected, setSelected] = useState(null);
  const [skipVariants, setSkipVariants] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(!!editIndex);
  const [activatedPositiveReply, setActivePositiveReply] = useState(!!item?.iParams?.positiveReply);
  const [connectedQuestion, setConnectedQuestion] = useState();

  // Points variants
  const [points, setPoints] = useState(false);
  const [variant, setVariant] = useState(defVariant);
  const [variants, setVariants] = useState([]);
  const [variantIndex, setVariantIndex] = useState();

  const refForm = useRef(null);
  const refWarning = useRef(null);
  const refName = useRef(null);

  useEffect(() => {
    if (!item) return;

    if (!!item?.iParams?.variants && !item?.name && !item?.label)
      item.iParams.variants = [];

    setQuestion();

    let ip = item?.iParams;
    if(!!ip?.params && !ip?.parameters){
      ip.parameters = ip.params;
      delete ip.params;
    }

    setTimeout(() => {
      if (!!ip && (!ip?.variants || ip?.variants?.length === 0) && !ip?.pointsVariants
        && ip?.parameters.indexOf("diagram") > -1) {
        ip.parameters = ip?.parameters.filter(x => x !== "diagram");
        ip.positiveReply = "";
      }

      if (editing && !!ip?.positiveReply && ip?.pointsVariants?.length > 0)
        setIParams({
          ...iParams,
          positiveReply: ip?.pointsVariants.find(
            (x) => x.value.toString() === ip?.positiveReply.toString()
          ),
        });

      if (!item?.item) {
        let objToChange = item;
        objToChange.item = json?.form.find(
          (x) => x.type === question?.type
        )?.item;
        setQuestion(objToChange);
      } else {
        sessionStorage.setItem("scrollIndex", questions?.count ?? 0);
        setQuestion(item);
      }

      if (!!ip?.pointsVariants && ip?.pointsVariants?.length > 0) {
        setPoints(true);
        setVariants(ip?.pointsVariants);
      }

      getInputsList();
    }, 10);

    setTimeout(() => { setLoading(false); }, 2000);
    setTimeout(() => {  setDelay(""); }, 4000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (questions?.length === 0 || !iParams?.connectedTo) return;
    setConnectedQuestion(
      questions?.find((x) => x.name === iParams?.connectedTo?.name)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  useEffect(() => {
    setDisabled(!!question?.id || !!selected);
  }, [selected, question]);

  const handleDrawnChange = (value) => {
    if (!selected || changed) return;

    if (value === "name" || value === "label") {
      setQuestion({ ...question, id: "", name: "", label: "" });
      handleSelected(null);
    }

    setChanged(true);
  };

  // Get data
  const getInputsList = async () => {
    setCurrentsInputs();
    await ApiRequest(`form/questions/by/${item?.type}`).then((res) => {
      let fields = res.data;
      const existing = questions.filter(x => x.type === item?.type);

      if (existing?.length > 0) {
        fields = fields.filter(x => {
          let index = existing.findIndex(e => e.name === x.name || e.label === x.label);
          if (index === -1)
            return x;

          return null;
        })
      }

      setCurrentsInputs(fields);
      if (!editing) {
        if (questionParams?.name !== undefined) handleSelected(null);
      } else {
        const field = fields.find(
          (x) => x.name === question.name && x.label === question.label
        );
        if (field != null) setSelected(field);
      }
    });
  };

  // Handle change
  const inputChange = (e) => {
    const name = e.target.name;
    let value = e.target.value === " " ? "" : e.target?.value;

    setQuestion({ ...question, [name]: value });
    handleDrawnChange(name);
    setError();
    setRepeated();
  };

  const iParamsChange = (e) => {
    const { name, value } = e?.target;
    setIParams({ ...iParams, [name]: value });
  };

  const handleParams = (e) => {
    const value = !e.target;
    const param = !value ? e.target.name : e;
    let params = iParams.parameters ?? [];
    if (params?.indexOf(param) > -1) {
      params = params?.filter((x) => x !== param);
    } else params.push(param);

    if (!value && e === "%") params = params.filter((x) => x !== "diagram");

    setIParams({ ...iParams, parameters: params });
  };

  const handleParamsList = (name, equal) => {
    let params = iParams?.parameters ?? [];
    if (params?.length > 0) {
      setIParams({
        ...iParams,
        parameters: params?.filter((x) => (equal ? x === name : x !== name)),
      });
    }
  };

  const handleSelected = (obj) => {
    setError();
    setRepeated();
    resetWarning();

    if (questionParams?.iParams?.variants !== undefined)
      setQuestion({
        ...question,
        id: null,
        name: "",
        label: "",
        variants: [],
        pointsVariants: null,
      });
    else setQuestion({ ...question, id: null, name: "", label: "" });
    setIParams(buildParams);
    setVariants([]);
    setPoints(false);
    setSelected(null);

    if (obj == null) return;

    setTimeout(() => {
      const keys = Object.keys(obj);
      const valueToUpdate = question;
      valueToUpdate.id = obj?.id;
      Object.keys(valueToUpdate).forEach((key) => {
        if (keys.indexOf(key) > -1 && key !== "type")
          valueToUpdate[key] = !!obj
            ? obj[key]
            : Array.isArray(item[key])
              ? []
              : "";
      });

      // Check selected is exist or not
      if (checkQuestionExisting(valueToUpdate))
        return;

      setQuestion(valueToUpdate);
      setSelected(valueToUpdate);
      setChanged(false);
      setVariants(obj?.pointsVariants);
      setPoints(obj?.pointsVariants?.length > 0);
    }, 100);
  };

  const listTypeChange = (value) => {
    if (!value) return;

    handleDrawnChange();
    let arr = [];
    setIParams({ ...iParams, column: 1 });

    switch (value) {
      case "list":
        if (points && variants?.length > 0)
          variants.forEach((v) => {
            arr.push(v?.label);
          });
        else arr = iParams?.variants;

        setQuestion({ ...question, type: "list", item: "Rull Listan" });
        setIParams({ ...iParams, variants: arr });
        break;
      default:
        setPoints(variants.length > 0);
        setQuestion({ ...question, type: value, item: value });
        setIParams({
          ...iParams,
          variants: variants?.length > 0 ? variants : iParams?.variants,
        });
        break;
    }
  };

  // Handle variants
  const switchVariants = () => {
    setPoints(!points);
    setVariantIndex();
    handleParamsList("fieldOther", false);

    let arr = [];
    var lg = iParams?.variants?.length;
    var vlg = variants?.length;
    if (!points && lg > 0 && vlg === 0) {
      for (let i = 0; i < lg; i++) {
        arr.push({
          label: iParams?.variants[i],
          value: lg - 1 - i,
        });
      }
      setVariants(arr);
    } else if (points) {
      if ((!lg || lg === 0) && vlg > 0) {
        for (let i = 0; i < vlg; i++) {
          arr.push(variants[i]?.label);
        }
        setIParams({ ...iParams, variants: arr });
      }
    }

    setIParams({
      ...iParams,
      positiveReply: "",
      parameters: iParams?.parameters?.filter(
        (x) => x !== "diagram" && x !== "fieldOther"
      ),
    });
    // setParams(params.filter(x => x !== "diagram" && x !== "fieldOther"));

    if (arr.length > 10) setWarning(warnings[0]);
  };

  const handleVariants = (e, enter) => {
    resetWarning();
    handleDrawnChange();

    let value = !!e ? e?.target?.value : enter;
    if (
      !!value &&
      question?.type === "slider" &&
      iParams?.sliderType !== "%" &&
      parseInt(value) !== 0 &&
      !parseInt(value)
    )
      return;

    let valueArray = [];

    if (value?.indexOf("\n") > -1) valueArray = value.split("\n");
    else if (value?.indexOf("<br/>") > -1) valueArray = value.split("<br/>");
    else if (enter) valueArray.push(value);

    let arr = iParams?.variants ?? [];
    if (variantIndex > -1 && valueArray.length > 0) {
      arr.splice(variantIndex, 1, value);
      setParam("");
    } else if (valueArray.length > 0) {
      valueArray.forEach((v) => {
        let matched = false;
        v = v.replace("<br/>", "");
        arr.forEach((a) => {
          if (a.toLowerCase() === v.toLowerCase()) matched = true;
        });
        if (v !== "" && !matched) arr.push(v);
        else if (matched)
          setWarning(warnings[1].replace("{msg}", "alternativ"));
      });

      if (arr.length > 10) setWarning(warnings[0]);

      setParam("");
    } else setParam(value);

    setIParams({ ...iParams, variants: arr });
  };

  const handleVariantPointChange = (e) => {
    const value = e.target.value;

    handleDrawnChange();
    resetWarning();

    if (!parseInt(value) && value !== "") return;

    setVariant({ ...variant, value: e.target.value });
  };

  const handlePointsVariants = (item, minus = false) => {
    handleDrawnChange();
    resetWarning();

    if (variantIndex > -1) {
      let arr = variants;
      arr.splice(variantIndex, 1, item);
      setVariants(arr);
      resetVariant();
    } else if (minus) {
      const arr = variants?.filter(
        (x) => x?.value !== item?.value && x?.label !== x?.value
      );
      setVariants(arr);
    } else {
      setVariants((variants) => [...variants, item]);
      setVariant(defVariant);
    }
  };

  const setDrawnVariants = (value) => {
    const arr = value.split(",");

    handleDrawnChange();
    setIParams({
      ...iParams,
      variants: arr,
      parameters: iParams?.parameters?.filter((x) => x !== "diagram" && x !== "fieldOther")
    });
  };

  const editVariant = (item, index) => {
    handleDrawnChange();
    setVariant(item);
    setVariantIndex(index);
  };

  const editParam = (item, index) => {
    setParam(item);
    setVariantIndex(index);
  };

  const resetVariant = () => {
    setParam("");
    setVariant(defVariant);
    setVariantIndex();
  };

  const removeFromVariants = (value) => {
    let arr = iParams?.variants ?? [];

    const index = arr?.indexOf(value);
    if (index > -1) {
      setTimeout(() => {
        setIParams({
          ...iParams,
          variants: iParams?.variants?.filter((x, ind) => ind !== index),
          positiveReply: "",
        });
        if (arr.length - 1 <= 0) handleParamsList("diagram", false);
      }, 100);

      if (warning === "variants" && arr.length - 1 <= 10) setWarning();

      handleDrawnChange();

      return true;
    } else return false;
  };

  const resetWarning = () => {
    setError();
    setWarning();
  };

  const dragStart = (e) => {
    setSwapIndex(e.target.id);
  };

  const dragDrop = (index) => {
    let arr = iParams?.variants ?? iParams?.pointsVariants;
    let swIndex = swapIndex;
    if (index > swapIndex) {
      for (var i = swIndex; i <= index; i++) {
        arr[i] = arr?.splice(swIndex, 1, arr[i])[0];
        swIndex = i;
      }
    } else {
      for (var x = swapIndex; x >= index; x--) {
        arr[x] = !!arr ? arr?.splice(swIndex, 1, arr[x])[0] : [];
        swIndex = x;
      }
    }
    // arr[index] = arr.splice(swapIndex, 1, arr[index])[0];
    setIParams({ ...iParams, variants: arr });
  };

  const dragEnd = () => {
    setSwapIndex(null);
  };

  const handleQuestionConnection = (ev) => {
    setError();

    let value = (ev?.target ? ev?.target?.value : ev)?.toString();

    let obj = iParams?.connectedTo ?? {
      label: connectedQuestion?.label,
      definite: false,
      name: connectedQuestion?.name,
      values: [],
    };

    if (obj?.values.indexOf(value) > -1 && ev.target?.type !== "number") {
      obj.definite = true;
      obj.values = obj.values.filter((x) => x !== value);
    } else if (ev.target?.type === "number") {
      if (parseInt(value))
        obj.values[0] = value;
    } else obj.values.push(value);

    setIParams({ ...iParams, connectedTo: obj });
  };

  const handleQuestionConnected = (value) => {
    setConnectedQuestion(_.isEqual(value, connectedQuestion) ? null : value);
    setIParams({ ...iParams, connectedTo: null });
    setError();
    setWarning();
  };

  const handleColumnChange = (e) => {
    setIParams({ ...iParams, column: e.target.value });
  };

  const checkQuestionExisting = (build) => {

    let exists = questions.find((x) => x.name === build?.name) != null;
    let message = `Beteckning är en unik nyckelord. Beteckning "${build.name}" används redan i enkät "${name}" för andra fältet.`;
    let error = "name";
    if (!exists) {
      exists = questions.find((x) => x.label === build?.label) != null;
      message = `Fråga med samma titel "${build?.label}" används redan i enkät "${name}" för andra fältet.`;
      error = "label";
    }

    if (editing ? exists && (item?.name !== build?.name && item?.label !== build?.label) : exists) {
      setWarning(warnings[1].replace("{msg}", "fråga"));
      setRepeated(message);
      setError(error);
      refWarning?.current?.scrollIntoView();
      refName?.current?.classList.add("warning-color");
      return true;
    } else
      return false;
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    let build = question;

    // Handle errors, control of build data
    let error = null;
    if (!!build?.value && build?.value?.length === 0)
      error = "description";
    else if (!!build?.label && build?.label?.length === 0)
      error = "label";
    else if (build?.name?.length < 3)
      error = "name";
    else if (!!iParams?.variants && (iParams?.variants?.length === 0 ||
      (build?.name === "slider" && iParams?.variants?.length < 2)))
      error = "variants";
    else if (["slider", "list", "radio", "checkbox", "rating"].indexOf(build?.type) > -1 && !skipVariants && (_.isEqual(iParams, buildParams)
      || ((!iParams?.variants || iParams?.variants?.length === 0) && variants?.length === 0))) {
      setWarning("Frågan saknar svarsalternativ.");
      setError("variants");
      return;
    } else if (!!build?.name && checkQuestionExisting(build))  // Check all form inputs names to similar
      return;

    if (!!error) {
      setWarning(warnings[2].replace("{msg}", ""));
      setError(error);
      return;
    }

    if (!!iParams) {
      let itemParams = iParams;

      if (!!connectedQuestion && !itemParams?.connectedTo) error = "connected";

      let params = iParams?.parameters;
      let reply = itemParams?.positiveReply;
      if (params?.indexOf("multiple") > -1 && iParams?.variants?.length > 10) {
        iParams.parameters = params?.filter((x) => x !== "diagram");
        reply = null;
      } else if (
        params?.indexOf("diagram") === -1 &&
        itemParams?.positiveReply?.length > 0
      )
        reply = null;

      if (variants?.length > 0 && points) {
        itemParams.variants = null;
        itemParams.pointsVariants = variants;
      } else
        itemParams.pointsVariants = null;

      if (!!reply && points)
        reply = itemParams?.positiveReply?.value.toString();

      itemParams.positiveReply = reply;

      build.iParams = itemParams;
    }

    if (!build.id && build.name !== undefined) {
      let params = {
        name: build.name,
        label: build?.label,
        type: build?.type,
      };
      
      await ApiRequest("form/check/field/existing", "post", params).then(
        (res) => {
          if (res.data === null)
            handleForm(build);
          else {
            setWarning(res.data.msg.replace("typ", `typ ${build?.name}`));
            setTimeout(() => {
              refWarning?.current?.scrollIntoView();
            }, 100)
          }
        }
      );
    } else handleForm(build);
  };

  let questionsToConnect = questions.filter((x) => !!x.name && x?.name !== question?.name);
  if (!!editIndex)
    questionsToConnect = questionsToConnect.filter((x, ind) => ind < (editIndex > 1 ? editIndex - 2 : editIndex));

  const ipVariants = points ? variants : iParams?.variants;
  const vlg = ipVariants?.length;
  const slider = question?.type === "slider";
  const connectedTo = iParams?.connectedTo ?? null;
  const cqValues = connectedTo?.values ?? [];
  const column = iParams?.column;
  const params = iParams?.parameters ?? [];
  const connected = params?.indexOf("connected") > -1;

  const variantsProps = points ? { onChange: (e) => setVariant({ ...variant, label: e.target.value }) }
    : { inputProps: { 
        maxLength: question?.type === "upload" ? 5 : 200 },
      InputProps: {
        endAdornment: param?.length > 0 ? (
            <div className="d-row">
              <IconButton onClick={resetVariant}>
                <Close />
              </IconButton>
              <IconButton onClick={() => handleVariants(null, param)}>
                <KeyboardReturn />
              </IconButton>
            </div>
          ) : null,
      },
      placeholder: `${!!question?.placeholder ? question?.placeholder + " " : ""} Tryck Enter att lägga till.`, onKeyUp: (e) => {
        if (e.key === "Enter") handleVariants(e, true);
      },
      onChange: handleVariants,
    };

  if (loading)
    return <Loading color="primary" size={30} style={{ height: "100%" }} msg="Data hämtas ..." />;

  return (
    <form className="fb-modal fade-in" onSubmit={onSubmit}>
      <InputLabel className="d-row jc-start ai-end">
        {question?.img} {question?.item}
      </InputLabel>

      {/* Error/Warning message */}
      {!!warning && <div className="form-control" ref={refWarning}>
        <Alert color="warning" severity="warning" onClose={resetWarning}>
          {warning}
        </Alert>
      </div>}

      {/* List of inputs */}
      {currentInputs?.length > 0 && question && (
        <div className="w100 box-none">
          <FormFields inputs={[{ label: "Frågor", name: "question", type: "autocomplete", variants: currentInputs, param: "label" }]}
            initData={{ question: !!question?.label ? question : null }}
            onValueChange={handleSelected}
          />
        </div> )}

      {/* Label to the selected field */}
      <TextField
        label={!!questionParams?.multiline ? "Text" : "Titel"}
        placeholder="Din text här ..."
        name={!!questionParams?.multiline ? "value" : "label"}
        className="fbm-input"
        required
        multiline={!!questionParams?.multiline}
        minRows={questionParams?.multiline ?? 1}
        value={ !!question?.value
            ? question?.value?.replaceAll("</br>", "\n")
            : question?.label ?? ""
        }
        error={["value", "label"].indexOf(error) > -1}
        color={["value", "label"].indexOf(error) > -1 ? "error" : ""}
        helperText={["value", "label"].indexOf(error) > -1 ? repeatedName : null} 
        disabled={disabled}
        onChange={inputChange}
      />

      {/* Name/Keyword to the selected field */}
      {item?.name !== undefined && (
        <>
          <TextField
            label="Beteckning"
            placeholder="En unik nyckelord. Obs! Bokstäver (engelska alfabetet) och siffror, ingen annan tecken eller mellanslag."
            name="name"
            ref={refName}
            className="fbm-input"
            required
            inputProps={{
              minLength: 3,
            }}
            value={question?.name ?? ""}
            error={error === "name" || !!repeatedName}
            color={error === "name" || !!repeatedName ? "error" : ""}
            helperText={error === "name" ? repeatedName : null} 
            disabled={disabled}
            onChange={inputChange}
          />

          {/* Info field */}
          <TextField
            label="Hjälp information"
            placeholder="Din text här ..."
            name="info"
            className="fbm-input"
            multiline
            value={iParams?.info ?? ""}
            onChange={iParamsChange}
          />

          {/* The additional field for selected field variants to select */}
          {["checkbox", "radio", "list", "slider", "rating"].indexOf(question?.type) > -1 &&
            <div className="d-column variants-container">

              <div className="d-row jc-between">

                {/* Switch variants from to points based or contrariwise */}
                {question?.type === "radio" &&
                  <FormControlLabel
                    className="variants-switch"
                    control={<Switch onChange={switchVariants} checked={points} />}
                    label="Poängbaserat" />}

                {/* List for variants */}
                {!!question?.variantsList && (
                  <div className="d-row jc-end choice-buttons">
                    {question?.variantsList?.map((x, ind) => {
                      return <Button key={ind} variant="text"  onClick={() => setDrawnVariants(x?.value)}
                        disabled={x?.value === ipVariants?.join(",") || points} >{x.label} </Button>
                    })}
                  </div>
                )}

                {/* List for slider types */}
                {!!questionParams?.sliderTypes && (
                  <div className="d-row jc-end choice-buttons">
                    {questionParams?.sliderTypes?.map((x, ind) => {
                      return <Button key={ind} variant="text"  onClick={() => setIParams({ ...iParams, sliderType: x.value }) } disabled={x.value === iParams?.sliderType}>
                          {x.label}
                        </Button>;
                    })}
                  </div>
                )}
              </div>

              {/* Variants */}
              {!skipVariants && <div className="d-row variants-fields-wrapper">
                <TextField
                  label="Svarsalternativ"
                  name="variants"
                  value={points ? variant?.label : param ?? ""}
                  multiline
                  required={vlg === 0 || (vlg < 2 && slider)}
                  disabled={ !variantIndex && (vlg === 2 && slider)}
                  className={ vlg > 0 && !points ? "variants-input" : "fbm-input"}
                  error={error === "variants"}
                  color={error === "variants" ? "error" : ""}
                  {...variantsProps}
                />

                {/* Points for variant*/}
                {points && (
                  <>
                    <TextField label="Poäng" required aria-label="number_input" value={variant?.value ?? 0}
                      placeholder={item?.placeholder ?? ""} className="fbm-input"
                      disabled={!variant?.label} onChange={handleVariantPointChange} />

                    <Button variant="outlined" color="error" className="points-buttons"
                      onClick={resetVariant} disabled={_.isEqual(defVariant, variant)}>
                      <Close />
                    </Button>
                    <Button variant="contained" className="points-buttons"
                      onClick={() => handlePointsVariants(variant)} disabled={!variant?.label} >
                      <KeyboardReturn />
                    </Button>
                  </>
                )}
              </div>}

              {/* Variants list */}
              {vlg > 0 && (
                <FormGroup row={question.type !== "list"} className="variants-wrapper" >
                  {/* List */}
                  {ipVariants?.map((item, ind) => {
                    return (
                      <Box key={ind} draggable onDragStart={dragStart} onDrop={() => dragDrop(ind)}
                        onDragEnd={dragEnd} id={ind}
                        style={column > 0 ? {
                              width: `calc((100% / ${column}) - 7px)`,
                              margin: "auto",
                            } : null}
                        className="list-variant d-row jc-between"
                        variant={parseInt(swapIndex) === ind ? "contained" : "outlined"} >
                        <div className="d-row">
                          {question?.type === "checkbox" && <CheckBoxOutlineBlank />}
                          {question?.type === "radio" && <RadioButtonUnchecked />}
                          {question?.type === "list" && <List />}

                          <span>
                            {item?.label ? item?.label + " (" + item?.value + " poäng)" : item}
                          </span>
                          {question?.type === "slider" && iParams?.sliderType}
                        </div>

                        <div className="variant-btns d-row">
                          <IconButton
                            onClick={() => points ? editVariant(item, ind) : editParam(item, ind) } >
                            <Edit />
                          </IconButton>
                          <IconButton  onClick={() =>
                              points  ? handlePointsVariants(item, true) : removeFromVariants(item) } >
                            <Close color="error" />
                          </IconButton>
                        </div>
                      </Box>
                    );
                  })}

                  {/* Variant for free text */}
                  {params?.indexOf("fieldOther") > -1 && (
                    <Box style={ column > 0 ? {
                            width: `calc((100% / ${column}) - 7px)`,
                            margin: "auto"
                          } : null }
                      className={`list-variant d-row jc-between blink-color${delay}`} variant="outlined">
                      <div className="d-row">
                        {question?.type === "checkbox" && <CheckBoxOutlineBlank />}
                        {question?.type === "radio" && <RadioButtonUnchecked />}
                        {question?.type === "list" && <List />}

                        <span>Annat</span>
                      </div>
                      <div className="variant-btns d-row">
                        {/* <IconButton onClick={() => setParams(params.filter(x => x !== "fieldOther"))}><Close color="error" /></IconButton> */}
                        <IconButton
                          onClick={() => handleParamsList("fieldOther", false)}
                        >
                          <Close color="error" />
                        </IconButton>
                      </div>
                    </Box>
                  )}
                </FormGroup>
              )}

              {/* Addition options for variants */}
              {question?.type !== "slider" && (
                <div className={`d-row ${editing ? "jc-between" : "jc-end"}`} style={{ marginTop: "20px" }}>

                  {/* Edit, change input */}
                  {editing && <div className="d-row">
                      <ChangeCircleOutlined color="primary" style={{ marginRight: "10px" }} />
                      {["checkbox", "radio", "list"].filter((x) => x !== question?.type).map((x, ind) => {
                          return <Button key={ind} onClick={() => listTypeChange(x)}> {x}</Button>;
                        })}
                    </div>}

                  {/* Checkbox choice row or column */}
                  {vlg > 1 && question?.type === "list" && (
                    <FormControlLabel
                      id="choice"
                      className="checkbox-wrapper no-margin"
                      control={
                        <Checkbox
                          size="medium"
                          name="multiple"
                          checked={params?.indexOf("multiple") > -1}
                          onChange={handleParams}
                        />
                      }
                      label={<span>Flerval</span>}
                    />
                  )}

                  {/* Select columns count for one row */}
                  {vlg > 1 && ["list", "slider", "rating"].indexOf(question?.type) === -1 && (
                      <FormControl className="select-wrapper no-margin" ref={refForm}>
                        <InputLabel id="demo-simple-select-label">Kolumn</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" defaultValue={column} value={column}
                          label="Kolumn" onChange={handleColumnChange} >
                          {[0, 1, 2, 3, 4].map((x) => {
                            return (
                              <MenuItem key={x} value={x}> {x > 0 ? `${x} i raden` : `Blandad`}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                </div>
              )}
            </div>}

          {/* Additional field for choice "Other" & "Free text" */}
          {["radio", "checkbox", "slider", "list"].indexOf(question?.type) > -1 &&
            ipVariants?.length > 0 && (
              <>
                {/* Other variant */}
                {(question.type !== "slider" && !points) && (
                  <FormControlLabel id="other" color="error"
                    className={`d-row jc-start checkbox-wrapper no-margin blink-color${delay}`}
                    control={
                      <Checkbox size="medium" name="fieldOther"
                        checked={params?.indexOf("fieldOther") > -1} onChange={handleParams}
                      />
                    } label={
                      <span> Alternativ val "Annat" med fält för fri variant </span>
                    }
                  />
                )}

                {/* Free text */}
                <FormControlLabel
                  id="freeText" color="error"
                  className={`d-row jc-start checkbox-wrapper no-margin blink-color${delay}`}
                  control={
                    <Checkbox size="medium" name="freeText"
                      checked={params?.indexOf("freeText") > -1}
                      onChange={handleParams}
                    />
                  } label={<span>Alternativ fält för fritext</span>} />
              </>
            )}

          {/* If checkbox has no variants */}
          {(question?.type === "checkbox" && (!ipVariants || ipVariants?.length === 0)) && <FormControlLabel
            id="required"
            className="d-row jc-start checkbox-wrapper no-margin"
            control={
              <Checkbox
                name="required"
                checked={skipVariants}
                onChange={() => setSkipVariants(!skipVariants)}
              />
            }
            label={<span>Frågan utan svarsalternativ</span>}
          />}

          {/* Required */}
          <FormControlLabel
            id="required"
            className="d-row jc-start checkbox-wrapper no-margin"
            control={
              <Checkbox
                name="required"
                checked={params?.indexOf("required") > -1}
                onChange={handleParams}
              />
            }
            label={<span>Obligatoriskt fält</span>}
          />

          {/* Add to diagram show */}
          {buildParams?.params?.indexOf("diagram") > -1 && (question?.type === "slider" ? vlg === 2 : vlg > 1 && vlg <= 10) && (
            <FormControlLabel
              id="diagram"
              className={`d-row jc-start checkbox-wrapper no-margin blink-color${delay}`}
              control={
                <Checkbox
                  checked={params?.indexOf("diagram") > -1}
                  name="diagram"
                  onChange={handleParams}
                />
              }
              label={<span>Svar i diagrammodell</span>}
            />
          )}

          {/* Add to diagram show */}
          {question && params?.indexOf("diagram") > -1 && vlg > 0 && vlg <= 10 && (
            <FormControlLabel
              id="diagram"
              className={`d-row jc-start checkbox-wrapper no-margin blink-color${delay}`}
              control={
                <Checkbox
                  checked={activatedPositiveReply}
                  onChange={() => setActivePositiveReply(!activatedPositiveReply)}
                />
              }
              label={<span>Ange ett positivt svar</span>}
            />
          )}

          {/* Set the positive variants for diagram */}
          {activatedPositiveReply && (
            <div className={`form-control box-none blink-color${delay}`}>
              {question?.type !== "slider" ? (
                <FormFields
                  inputs={[
                    {
                      name: "positiveReply",
                      type: "autocomplete",
                      variants: ipVariants,
                      param: "label",
                      required: activatedPositiveReply,
                      label:
                        "Alternativ som kan räknas som ett positivt svar",
                      info: "Välj inget alternativ om frågan ska visas i en diagrammodell utan något positivt svar.",
                    },
                  ]}
                  initData={{ positiveReply: iParams?.positiveReply }}
                  onValueChange={(option) =>
                    setIParams({ ...iParams, positiveReply: option })
                  }
                />
              ) : (
                <TextField
                  aria-label="number_input"
                  type="number"
                  inputProps={{
                    min: ipVariants[0],
                    max: ipVariants[1],
                  }}
                  value={iParams?.positiveReply}
                  placeholder={`Välj en siffra mellan ${ipVariants[0]} och ${ipVariants[1]}`}
                  name="positiveReply"
                  error={error === "positiveReply"}
                  color={error === "positiveReply" ? "error" : ""}
                  required={activatedPositiveReply}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (
                      (!parseInt(value) && value !== "") ||
                      parseInt(value) < parseInt(ipVariants[0]) ||
                      parseInt(value) > parseInt(ipVariants[1])
                    )
                      return;
                    setIParams({ ...iParams, positiveReply: e.target.value });
                  }}
                />
              )}
            </div>
          )}

          {/* Show depending on the answer to the question  */}
          {questionsToConnect?.length > 0 && (
            <FormControlLabel
              id="connected"
              className="d-row jc-start checkbox-wrapper no-margin"
              required={!!connectedTo}
              control={
                <Checkbox
                  checked={connected}
                  name="connected"
                  onChange={(e) => {
                    handleParams(e);
                    setConnectedQuestion(null);
                    if (!!iParams?.connectedTo)
                      setIParams({ ...iParams, connectedTo: null });
                  }}
                />
              }
              label={<span>Kopplad till frågan</span>}
            />
          )}

          {/* Survey questions that can be selected when the build question "related to question" checkbox is selected. */}
          {connected && (
            <div className={`d-column connection-box blink-color${delay}`}>
              {/* QUestions list */}
              <DropdownList label={connectedQuestion?.label ?? "Fråga"} name="label" list={questionsToConnect} icon={<QuestionMarkOutlined />} elected={connectedQuestion?.name} checked="name"
                error={error === "connected"} handleDropdown={setDropdown} onClickHandle={handleQuestionConnected} />

              {!dropdown && !!connectedQuestion && (
                <div className="form-control d-column">
                  <FormLabel className="label">
                    <span dangerouslySetInnerHTML={{ __html: `Välj ${!connectedQuestion?.type === "number" ? "talet" 
                        : (connectedTo?.variants ? "svarstextlängd" : "svarsalternativ")}  för att visa fältet: </br>- ${question?.label}`}}></span>
                  </FormLabel>

                  {/* Variants to select for connected question with variants */}
                  {connectedQuestion?.iParams?.variants?.map((value, ind) => {
                    return (
                      <Button key={ind} onClick={() => handleQuestionConnection(value)} startIcon={ cqValues.indexOf(value) > -1 ? <CheckBox color="primary" />
                           : <CheckBoxOutlineBlank color={error && !cqValues && !!connectedTo ? "error" : "inherit"} />}>
                        {value}
                      </Button>
                    );
                  })}

                  {/* Input  to enter the answer length or a number as answer for a related question */}
                  {!connectedQuestion?.iParams?.variants && <TextField  aria-label="number_input"  value={cqValues[0] ?? 0} type="number" onChange={handleQuestionConnection} />}
                </div>
              )}
            </div>
          )}
        </>
      )}

      {/* Buttons  */}
      <FormButtons
        reset={reset}
        value={<Done />}
        disabled={error || !!repeatedName}
      />
    </form>
  );
}

export default FormBuild;
