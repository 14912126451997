

function FormBlock({children, form, hide }) {
    FormBlock.displayName = "FormBlock";

    return (
        <div className='view-form-content d-column jc-start w-100'>

            {/* Short information */}
            <div className='d-row jc-between w-100'>
                <h2>{form?.name}</h2>
                <div className='short-information'>
                    {children}
                    <p><span>Antal frågor:</span>{form?.questionsCount}</p>
                </div>
            </div>

            {(!hide && !!form?.description) &&
                <p className="fbo-description w-100" dangerouslySetInnerHTML={{ __html: form?.description }}></p>}
        </div>
    )
}

export default FormBlock