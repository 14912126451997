import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

// Installed
import { Alert, Button, IconButton, TextField } from '@mui/material';
import { ArrowBack, Close, Edit, Search, Send } from '@mui/icons-material';

// Components
import LinearLoading from '../../components/LinearLoading';
import FormBlock from '../../components/FormBlock';

// Functions
import { ErrorHandle } from '../../functions/ErrorHandle';

// Services
import ApiRequest from '../../services/ApiRequest';

function FormView() {
    FormView.displayName = "FormView";

    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(true);
    const [send, setSend] = useState(false);
    const [redaction, setRedaction] = useState(false);
    const [supplier, setSupplier] = useState("Ej angiven");
    const [close, setClose] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [sort, setSort] = useState(null);

    const { id, mode } = useParams();
    const navigate = useNavigate();
    const loc = useLocation();

    useEffect(() => {
        setLoading(true);
        async function getFormData() {
            await ApiRequest(`form/${id}`).then(res => {
                if (res.data) {
                    setForm(res.data);
                    document.title = "Enkät: " + res.data?.name;
                }
                setTimeout(() => {
                    setLoading(false);
                }, 1000)
            }, error => {
                setLoading(false);
                ErrorHandle(error, navigate);
            });
        }

        setRedaction(loc.pathname.indexOf("edit") > -1);
        setSend(loc.pathname.indexOf("send") > -1);

        if (!id) return;
        getFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loc])

    const changeSupplier = (value) => {
        setSupplier(!value ? "Ej angiven" : value);
    }

    const navigationBack = () => {
        setClose(true);
        setTimeout(() => {
            setClose(false)
        }, 1000)
    }

    return (
        <div className={`${loading ? 'view-form-loading w-100' : "view-form-wrapper fade-in"} w-100`}>

            {/* Loading */}
            {loading && <LinearLoading msg="enkät laddas upp ..." color="success" size={30} />}

            {/* Menu */}
            {!loading && <div className='view-form-panel w-100'>
                <div className='d-row jc-between w-100'>
                    <p>{send ? "Skicka enkät" : (redaction ? "Redigera enkät" : "Enkät översikt")}</p>
                    <div className='d-row'>

                        {/* Sorting input */}
                        {(!!redaction && !cancel) && <div className='search-wrapper'>
                            <TextField
                                className='search-input'
                                name="sort"
                                type="text"
                                value={sort ?? ""}
                                onChange={(e) => setSort(e.target?.value)}
                            />
                            <IconButton disabled={sort?.length === 0} onClick={() => setSort(null)}>
                                {sort?.length > 0 ? <Close color="error" /> : <Search />}
                            </IconButton>
                        </div>}

                        <IconButton onClick={() => navigate(`send/survey/${id}`)} disabled={send || redaction || !form?.name}>
                            <Send color="success" />
                        </IconButton>
                        <IconButton onClick={() => navigate(`/edit/survey/${id}`)} disabled={redaction || form?.connected}>
                            <Edit color="primary" />
                        </IconButton>
                        <IconButton onClick={() => !cancel ? navigate(-1) : navigationBack()}>
                            <ArrowBack color="warning" />
                        </IconButton>
                    </div>
                </div>
            </div>}

            {/* Content */}
            {!redaction && <FormBlock form={form} hide={send}>
                <p>
                    <span>{send ? "Leverantör" : "Kategori"}:</span>
                    {!send && <Button onClick={() => navigate(`/categories/${form.category?.keyword}/survey`)}>
                        {form?.category?.name}</Button>}
                    {send && supplier}
                </p>
            </FormBlock>}

            {/* Warning that the survey is a test */}
            {mode && <Alert variant='outlined' severity='warning' className="send-form" color="warning">
                Testartikeln
            </Alert>}

            {/* Pages by url */}
            {!loading && <Outlet context={{ id, form, loading, close, sort, categoryId: form?.category?.id, visible: !loading, navigate, changeSupplier, deactivateNavigate: setCancel }} />}
        </div>
    )
}

export default FormView;