import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import _ from "lodash";

// Installed
import { IconButton } from "@mui/material";
import { Close, EditNote, Input } from "@mui/icons-material";

// Components
import Container from "./Container";
import FormInputs from "./FormInputs";
import FormBuild from "./FormBuild";
import Modal from "./Modal";
import FormManage from "./FormManage";
import Response from "./Response";
import Loading from "./Loading";
import DropdownList from "./DropdownList";

// Functions
import { ErrorHandle } from '../functions/ErrorHandle';

// Services
import ApiRequest from "../services/ApiRequest";


function FormSurvey({ redaction, visible, permission }) {
  FormSurvey.displayName = "FormSurvey";

  const { id, navigate, category, close, sort, expandHandle, deactivateNavigate } = useOutletContext();

  const [formInputs, setFormInputs] = useState([]);
  const [savedQuestions, setSavedQuestions] = useState("");
  const [hidden, setHidden] = useState(false);
  const [drawn, setDrawn] = useState();
  const [build, setBuild] = useState(false);
  const [classEnd, setClassEnd] = useState("wrapper");
  const [overview, setOverview] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [skip, setSkip] = useState(true);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState();
  const [response, setResponse] = useState();

  useEffect(() => {

    if (!id) {
      var inputs = JSON.parse(localStorage.getItem("savedForm"));
      if (inputs) {
        setFormInputs(inputs);
        setOpen(!redaction);
      }
    } else {
      async function getInputs() {
        await ApiRequest(`form/view/${id}`).then(res => {
          if (res.data) {
            const form = res.data.form;

            setForm(form);
            setHidden(form?.hidden);

            const formQuestions = [
              { item: "Title", label: form?.name, type: "name", missed: "Titel saknas (obligatoriskt) *" },
              { item: "Beskrivning", value: form?.description, multiline: 7, type: "description", missed: "Beskrivning saknas (ej obligatoriskt)" }
            ];

            const fields = formQuestions.concat(res.data.inputs);
            setSavedQuestions(JSON.stringify(fields));
            setFormInputs(fields);

            if (visible)
              updateStoredNames(form, fields);
            setOverview(true);
          }
        }, error => setResponse(ErrorHandle(error, navigate)));
      }

      getInputs();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (close)
      cancelActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close])


  const dragStartHandle = (value) => {
    setSkip(false);

    const item = value;
    if (item?.variants?.length > 0)
      item.variants = [];
    setBuild(false);
    setDrawn(item);
    if (!!deactivateNavigate)
      deactivateNavigate(false);
    setOverview(false);
  }

  const dropHandle = () => {
    if (skip) return;

    setBuild(true);
    setSkip(true);
    setClassEnd("wrapper");
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    if (skip) return;

    setClassEnd("hover");
  }

  const handleDragLeave = () => {
    if (skip) return;

    setDrawn(null);
    setClassEnd("wrapper");
  }

  const linkClickHandle = (value) => {
    setDrawn({});
    setBuild(false);

    setTimeout(() => {
      setDrawn(value);
      setBuild(true);
      if (!!deactivateNavigate)
        deactivateNavigate(true);
    }, 0)

    setOverview(false);
  }

  const handleForm = (item) => {

    let arr = formInputs;
    const update = typeof editIndex === "number";

    if (!!item?.value)
      item.value = item?.value?.replaceAll("\n", "</br>");

    if (update)
      arr[editIndex] = item;
    else
      arr.push(item);

    // Find index of description if this exists and place to right position of inputs
    var descIndex = arr.findIndex(x => x.value !== undefined);
    if (descIndex > -1 && descIndex !== 1) {
      for (var x = descIndex; x >= 1; x--) {
        arr[x] = arr.splice(descIndex, 1, arr[x])[0];
      }
    }

    setFormInputs(arr);

    if (!update && !redaction)
      saveFormChange();

    if (!overview)
      setOverview(true);

    cancelActions();
  }

  const updateForm = (value) => {
    if (typeof value !== "boolean") {
      setFormInputs([]);
      setTimeout(() => {
        setFormInputs(value);
        updateStoredNames(form, value);
      }, 100)
    } else
      setForm({ ...form, hidden: value });
  }

  const updateStoredNames = (form, fields) => {
    let names = fields.filter(x => !!x.name).map(x => x.name);
    sessionStorage.removeItem(form?.name.toLowerCase())
    sessionStorage.setItem(form?.name.toLowerCase(), JSON.stringify(names));
  }

  const saveFormChange = () => {
    localStorage.setItem("savedForm", JSON.stringify(formInputs));
  }

  const resetFormBuilding = (skip = false) => {
    setOverview(false);
    cancelActions(skip);
    setOpen(false);
    if (!!id) navigate(-1);
  }

  const closeModal = (error) => {
    if (!error)
      localStorage.removeItem("savedForm");
    setFormInputs([]);
    resetFormBuilding(true);
  }

  const cancelActions = (skip = false) => {
    setBuild(false);
    setDrawn(null);
    if (!!deactivateNavigate)
      deactivateNavigate(false);
    if (typeof skip === "boolean")
      setOverview(!skip);
    else if (!overview)
      setOverview(true);

    setEditIndex(null);
    setSkip(true);
  }

  const overviewHandle = () => {
    setOpen(false);
    setOverview(!overview);
    cancelActions();
    setDrawn();
  }

  const editing = formInputs?.find((x, ind) => ind === editIndex);

  // Block with questions fields
  const formManage = <div className="form-building-container d-row jc-between">

    {/* Question search */}
    {/* {redaction && <div className="w100">
      
    </div>} */}

    {/* Form building wrapper */}
    <div className={`d-column jc-start form-building-${classEnd}`}
      onDrop={dropHandle}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      data="container"
    >

      {/* List of suppliers already used for this survey, if available */}
      {form?.suppliers?.length > 0 && 
        <DropdownList label="Lista över leverantörer som redan har använts för denna undersökning" list={form?.suppliers} api={`form/supplier/${form?.id}`} removable={true} />}

      {/* Response */}
      {!!response && <Response res={response} reset={() => setResponse()} />}

      {/* Instructions block */}
      {(!id && ((!overview && !drawn) || (!drawn && formInputs?.length === 0))) &&
        <div className="fb-message-div d-column">
          <p>Dra och släp här eller dubbelklicka på</p>
          <Input />
        </div>}

      {/* Loading */}
      {(!!id && !response && ((!overview && !drawn) || (!drawn && formInputs?.length === 0))) &&
        <Loading color="primary" size={30} style={{ height: "calc(100vh - 300px)" }} msg="Data hämtas ..." />}

      {/* Form manage */}
      {(formInputs?.length > 0 && overview) && <FormManage
        // inputs={(!!sort && sort?.length > 0) ? formInputs.filter((x, index) => (x?.label || x?.name || x?.type)?.toLowerCase()?.includes(sort) || (index - 1)?.toString() === sort) : formInputs}
        // sortIndex={(parseInt(sort) && formInputs?.length >= parseInt(sort)) ? parseInt(sort) : null}
        inputs={formInputs}
        sort={sort}
        id={id}
        categoryId={category?.id ?? form?.category?.id}
        changed={!(_.isEqual(savedQuestions, JSON.stringify(formInputs))) || form.hidden !== hidden}
        visible={visible}
        mode={form?.hidden}
        permission={permission}
        updateForm={updateForm}
        handleEdit={(index) => setEditIndex(index)}
        close={() => navigate(-1)}
        saveChange={saveFormChange}
        resetForm={resetFormBuilding}
        closeModal={closeModal} />}

      {/* Form building  */}
      {(build && drawn) && <FormBuild
        item={drawn}
        name={form?.name}
        editIndex={editIndex}
        questions={formInputs}
        handleForm={handleForm}
        reset={cancelActions} />}
    </div>

    {/* Inputs variants */}
    <FormInputs
      build={build}
      drawn={drawn}
      editItem={editing}
      disLabel={formInputs[0]?.item !== "Title"}
      disDesc={formInputs[1]?.item === "Beskrivning"}
      handleClick={linkClickHandle} handleDrag={dragStartHandle} />
  </div>

  if (redaction)
    return formManage;

  return (
    <Container
      label="Nytt enkätformulär"
      buttons={(formInputs?.length > 0 && !build) ?
        <IconButton onClick={() => overview ? setOverview(false) : overviewHandle()}>
          {overview ? <Close color="error" /> : <EditNote color="inherit" />}
        </IconButton> : null}
      expands={true}
      hidden={true}
      expand={expandHandle}>

      {formManage}

      {/* Modal */}
      {open && <Modal
        buttonValue="Ja"
        closeButtonValue="Börja om"
        msg={`Sparade formulär${(!!formInputs ? ": " + formInputs[0]?.label : '')}`}
        content="Vill du fortsätta redigera den?"
        clickHandle={overviewHandle}
        close={closeModal} />}

    </Container>
  )
}

export default FormSurvey;