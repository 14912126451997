import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

// Installed
import { Archive, FormatListBulletedOutlined, ListAlt, Lock, LockClock, Settings } from '@mui/icons-material';
import { ListItem, List, ListItemAvatar, Avatar, ListItemText, IconButton } from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers';

// Components
import Loading from '../../components/Loading';
import Countdown from '../../components/Countdown';

// Functions
import { ErrorHandle } from '../../functions/ErrorHandle';
import { CheckNumber } from '../../functions/CheckNumber';

// Services
import ApiRequest from '../../services/ApiRequest';

// Css
import '../../assets/css/home.css';

function Home({ update }) {
    Home.displayName = "Home";

    const [categories, setCategories] = useState([]);
    const [margin, setMargin] = useState(0);
    const [scrolledIndex, setScrolledIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const width = window.innerWidth;

    useEffect(() => {
        setLoading(true);

        document.title = "Leverantörsuppföljning";
        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (update)
            getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    const getCategories = async () => {
        await ApiRequest(`category/dashboard`).then(res => {
            setLoading(false);
            setCategories(res.data);
        }, error => errorHandle(error))
    }

    const navigateTo = (url) => {
        navigate(`categories/${url}`)
    }

    const clickHandle = (ind) => {

        const innerWidth = window.innerWidth

        if (innerWidth < 1400 && (ind === categories.length - 1))
            return;
        else if (innerWidth >= 1400 && (ind === categories.length - 2))
            return;

        setScrolledIndex(ind);
        const width = innerWidth >= 1400 ? (1400 / 3) : ((innerWidth / 2) - 1);
        let pixel = width * ind;
        if (pixel > 0)
            pixel = -pixel;

        setMargin(`${pixel}px`);
    }

    const errorHandle = (error) => {
        setLoading(false);
        ErrorHandle(error, navigate);
    }

    if (loading)
        return <Loading size={60} />;

    return (
        <div className='d-column w100'>
            <div className={`home-categories d-row jc-start${categories.length > 3 ? " flex" : ""}`}>
                {categories?.map((ctg, index) => {
                    return <div className="category-column" style={index === 0 ? { marginLeft: margin } : null} key={index}>

                        {/* Category */}
                        <div className='category d-row'
                            onClick={() => navigate(`categories/${ctg.keyword}/surveys`)}>
                            {ctg.name}
                            <p className='bottom-border'></p>
                        </div>

                        {/* Statistic list */}
                        <List className='box-list'>
                            {[
                                { label: "Enkät", symbol: <FormatListBulletedOutlined />, count: ctg?.surveyForms, url: `${ctg.keyword}/surveys` },
                                { label: "Pågående", symbol: <ListAlt />, count: ctg?.surveyActive, url: `${ctg.keyword}/surveys/active` },
                                { label: "Stängda", symbol: <Lock />, count: ctg?.surveyClosed, url: `${ctg.keyword}/surveys/closed` },
                                { label: "Arkiv", symbol: <Archive />, count: ctg?.surveyArchives, url: `${ctg.keyword}/surveys/archive` },
                            ].map((item, ind) => {
                                return <ListItem key={ind} className='box-list-li d-row' secondaryAction={item.count} onClick={() => navigateTo(item?.url)}>
                                    <ListItemAvatar>
                                        <Avatar className='box-img'>{item.symbol}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={item?.label} />
                                </ListItem>
                            })}
                        </List>

                        {/* Current survey */}
                        {ctg?.surveys?.length > 0 && <List className='box-list'>

                            {ctg?.surveys.map((item, ind) => {
                                let result = !!item?.receivers?.result ? CheckNumber(item?.receivers?.result) : 0;
                                const filled = (result === 0 || result === 100);
                                return <ListItem key={ind} className='box-list-li overview d-column'
                                    secondaryAction={item.closed ? <Lock fontSize='medium' color='error' /> : <LockClock style={{ color: "#999999" }} />}
                                    onClick={() => navigate(`categories/${ctg.keyword}/survey/${item?.id}`)}>
                                    <ListItemText primary={<span dangerouslySetInnerHTML={{
                                        __html: `${item?.name}<br/><span class="supplier-name">
                                                        ${item?.supplierName}</span>`
                                    }}></span>}
                                        secondary={`Deltagare ${item?.receivers?.participants?.length} person`} />

                                    {/* Deadline */}
                                    <span className="d-row deadline-span">
                                        {!item?.closed ? <Countdown deadline={item?.deadlineSeconds} /> : <span style={{ color: "#D32F2F" }}>{item?.deadlineTime}</span>}            
                                    </span>

                                    {/* Settings */}
                                    {item?.hidden ? <Settings color="error" className="settings-cogs"/> : null}

                                    <PieChart
                                        series={[
                                            {
                                                arcLabel: (i) => `${i.value} %`,
                                                arcLabelMinAngle: 45,
                                                data: [
                                                    { id: "0", value: result, label: ` Färdig ${result}%`, color: "#18d107" },
                                                    { id: "1", value: 100 - result, label: ` Återstående ${100 - result}%`, color: "#a50029" },
                                                ],
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                                innerRadius: filled ? 60 : 35,
                                                outerRadius: 100,
                                                paddingAngle: filled ? 0 : 1,
                                                cornerRadius: 3,
                                                // startAngle: -90,
                                                // endAngle: 180,
                                                cx: -10,
                                                // cy: 150,
                                            },
                                        ]}
                                        sx={{
                                            [`& .${pieArcLabelClasses.root}`]: {
                                                fill: 'white',
                                                fontWeight: 'bold'
                                            }
                                        }}
                                        height={200}
                                        fontSize={40}
                                        margin={{ right: 400 }}
                                    />
                                </ListItem>;
                            })}

                        </List>}

                    </div>
                })}
            </div>

            <div className={`d-row bottom-panel${categories.length > 3 ? " visible" : ""}`}>
                <IconButton onClick={() => clickHandle(scrolledIndex - 1)} disabled={scrolledIndex === 0}><ArrowLeftIcon /></IconButton>
                {Array(categories?.length > 2 ? categories.length - (width >= 1400 ? 2 : 1) : 0).fill().map((i, ind) => {
                    return <p className={`switch-points${scrolledIndex === ind ? " selected-index" : ""}`} key={ind} onClick={() => clickHandle(ind)}>
                        <span></span>
                    </p>;
                })}
                <IconButton onClick={() => clickHandle(scrolledIndex + 1)} 
                    disabled={scrolledIndex === (categories.length - (width >= 1400 ? 3 : 2))}>
                        <ArrowRightIcon /></IconButton>
            </div>
        </div >
    )
}

// function CountDown(seconds) {

// }

export default Home;