import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Installed
import { IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link, ListOutlined } from "@mui/icons-material";

// Components
import Loading from "./Loading";
import Container from "./Container";

// Services
import ApiRequest from "../services/ApiRequest";

function Overview({ children, api, link, icon, json }) {

    const [data, setData] = useState();
    const [view, setView] = useState();
    const [list, setList] = useState();
    const [loading, setLoading] = useState(true);

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        async function getData() {
            await ApiRequest(`${api}/${id}`).then(res => {
                setLoading(false);
                if (res?.data != null) {
                    setData(res.data.obj);
                    if (!!res.data?.list)
                        setList(res.data?.list);
                    if (!!res.data?.view)
                        setView(res.data?.view);
                }
            })
        }

        getData();
    }, [api, id])

    return (
        <div className="view-form-wrapper view-wrapper fade-in w-100">
            {/* Loading */}
            {loading && <Loading msg="data hämtas ..." color="success" size={30} />}

            {/* Content */}
            {!loading && <Container
                label={(data?.label || data?.name) ?? "Fel"}
                hidden={true}>

                {/* View content */}
                {!!view && Object.keys(view).map((i, ind) => {
                    if (data[i] != null)
                        return <p>{data[i]}</p>;

                    return null;
                })}

                {/* Data list */}
                {!!list && <List className="w100 fade-in box-list">
                    {list?.map((item, ind) => {
                        return <ListItem className='d-row jc-start box-list-li-border' key={ind} secondaryAction={
                            <IconButton onClick={() => navigate(`${link}/${item?.id}`)} color="primary">
                                <Link color="primary" />
                            </IconButton>
                        }>
                            <ListItemIcon>
                                {icon ?? <ListOutlined />}
                            </ListItemIcon>
                            <ListItemText primary={item?.label || item?.name} />
                        </ListItem>
                    })}
                </List>}
            </Container>}
        </div>
    )
}

export default Overview