import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'

// Installed
import { Button } from '@mui/material';
import {ArrowRightAltOutlined } from '@mui/icons-material';

// Css
import '../../assets/css/notfound.css';

function NotFound({ isAuthorized }) {

    const navigate = useNavigate();

    const loc = useLocation();

    useEffect(() => {
        if(loc.pathname === "/logout"){
            setTimeout(() => {
                navigate("/");
            }, 1000)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if(loc.pathname === "/logout")
        return null;

    return (
        <div className='notfound-container d-row wh-100'>
            <div className="notfound-wrapper d-column">
                <div className='notfound-content'>
                    <p className='notfound-title'>Sidan finns inte.</p>
                    <Button variant="outlined" className='notfound-btn' onClick={() => navigate("/")} startIcon={<ArrowRightAltOutlined />}>
                        {isAuthorized ? "Startsidan" : "Login"}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NotFound;