import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

// Installed
import { Avatar, Button, CircularProgress, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Check, FmdBad, NoteOutlined, PieChart, QuestionAnswer, RateReview, TextFields, Verified } from "@mui/icons-material";

// Components
import Loading from "../../components/Loading";
import Response from "../../components/Response";

// Functions
import { ErrorHandle } from "../../functions/ErrorHandle";

// Services
import ApiRequest from "../../services/ApiRequest";

function ResponseView() {
    ResponseView.displayName = "ResponseView";

    const [responses, setResponses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState();
    const [sending, setSending] = useState(false);

    const { email, surveyId, navigate } = useOutletContext()

    useEffect(() => {
        async function getResponses() {
            await ApiRequest(`survey/responses/by/${email}/${surveyId}`).then(res => {
                setResponses(res.data?.responses);

                setTimeout(() => {
                    setLoading(false);
                }, 100)
            }, error => {
                setResponse(ErrorHandle(error, navigate));
                setLoading(false);
            });
        }

        if (!!email)
            getResponses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email])

    const sendRemindMail = async () => {
        setSending(true);
        await ApiRequest(`survey/remind/mail/${surveyId}/${email}`).then(res => {
            setSending(false);
            if (!!res.data)
                setResponse(res.data);
            else
                setResponse({ msg: "Skickad!" })
        }, error => {
            setResponse(ErrorHandle(error, navigate));
            setSending(false);
        });
    }

    if (loading)
        return <Loading color="inherit" msg="Data hämtas ..." size={40} style={{ height: "50%" }} />;


    return (
        <List className="section-box fade-in" style={{ width: "97%", margin: "auto" }}>
            <ListItemText primary="Frågor/Svar" />

            {/* If it is response from server */}
            {!!response && <Response res={response} reset={() => setResponse()} />}

            {/* If there are responses */}
            {!response && responses?.map((x, index) => {
                return <ListItem key={index} secondaryAction={x.diagram ? <PieChart color="disabled" />
                    : <TextFields color="disabled" />}>
                    <ListItemAvatar>
                        <Avatar style={{ backgroundColor: "#e7e7e76e" }}>
                            {(x.diagram && !x.fieldOther) && <>
                                {x?.positive && <Verified color="success" />}
                                {(x?.positiveReply && !x.positive) && <FmdBad color="warning" />}
                                {(!x.positiveReply && !x.positive) && <Check color="disabled" />}
                            </>}

                            {(!x?.diagram && !x.fieldOther) && <QuestionAnswer color="primary" />}
                            {x.fieldOther && <RateReview color="secondary" />}
                        </Avatar>
                    </ListItemAvatar>
                    <div className="d-column ai-start" style={{ opacity: (x.value.length === 0 && !x.fieldOther && !x.freeTexts) ? 0.3 : 1 }}>
                        <ListItemText primary={<span className="section-question d-row" dangerouslySetInnerHTML={{ __html: x.name }}></span>}
                            secondary={x.fieldOther ? `Annan svar: ${x?.value}` : (x?.value === true ? "Svar: ✔️" : (x?.value?.toString().length > 0 ? `Svar: ${x.value}` : "Inget svar"))} />
                        {!!x.freeText && <p className="free-text">- {x.freeText}</p>}
                    </div>
                </ListItem>
            })}

            {/* If there are no responses */}
            {(!response && !responses) && <ListItem className="box-list-li-border" secondaryAction={
                <Button onClick={sendRemindMail} style={{ width: "max-content" }}>
                    {sending ? <CircularProgress size={20} /> : "Skicka påminellse"}
                </Button>
            }>
                <ListItemAvatar>
                    <Avatar><NoteOutlined /></Avatar>
                </ListItemAvatar>
                <ListItemText primary="Undersökning obesvarad." />
            </ListItem>}
        </List>
    )
}

export default ResponseView;