// Installed
import { Route, Routes } from "react-router-dom";

// Pages
import Login from "../pages/oauth/Login";
import Survey from "../pages/oauth/Survey";
import NotFound from "../pages/auth/NotFound";

function AppRoutes() {
  const routes = [
    {
      index: true,
      path: "/",
      element: <Login />
    },
    {
      path: "customer/survey/:open/:modelId/:receiverId",
      element: <Survey />
    },
    {
      path: "customer/survey/:open/:modelId/:receiverId/:resend",
      element: <Survey />
    },
    {
      path:"/*",
      element: <NotFound isAuthorized={false} />
    }
  ];


  return <Routes>
    {routes.map((route, index) => {
      const { element, ...rest } = route;
      return <Route key={index} {...rest} element={element} />;
    })}
  </Routes>
}

export default AppRoutes;
