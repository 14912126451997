import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

// Installed
import { ArrowDownward, ArrowForward, Category, Delete, Edit, List } from '@mui/icons-material';
import { Avatar, Button, List as ListBox, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

// Components
import Modal from '../../components/Modal';

// Functions
import { ErrorHandle } from '../../functions/ErrorHandle';

// Services
import ApiRequest from '../../services/ApiRequest';

// Json
import json from '../../assets/json/inputs.json';

// Css
import '../../assets/css/section.css';

function Section({ update, permission }) {
  Section.displayName = "Section";

  const { key, path, page, param } = useParams();
  const loc = useLocation();
  const navigate = useNavigate();

  const [category, setCategory] = useState(null);
  const [selected, setSelected] = useState({});
  const [index, setIndex] = useState(-1);
  const [expanded, setExpanded] = useState(false);
  const [response, setResponse] = useState();

  const links = [
    { label: "Enkätformulär", name: "formulär", url: "surveys", link: "surveys", api: `form/category/${key}/${permission}`, searchParam: "description", count: "surveyForms" },
    { label: "Undersökningar", style: { margin: "30px 0 20px 0", pointerEvents: "none", cursor: "none" } },
    { label: "Aktuella", name: "aktuella undersökningar", class: " li-sub", url: "surveys/active", link: "active", api: `survey/${key}/${false}/${permission}`, searchParam: "description", count: "surveyActive" },
    { label: "Stängda", name: "stängda undersökningar", class: " li-sub", url: "surveys/closed", link: "closed", api: `survey/${key}/${true}/${permission}`, searchParam: "description", count: "surveyClosed" },
    { label: "Arkiv", name: "arkiv", url: "surveys/archive", class: " li-sub", link: "archive", api: `survey/${key}/archive`, searchParam: "description", count: "surveyArchives" }
  ]

  useEffect(() => {
    sessionStorage.removeItem("scrollIndex");
    if (!!key || update) getCategory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, update])

  useEffect(() => {
    setSelected();
    setExpanded(false);
    const pathnames = loc.pathname.split("/");
    const index = links?.findIndex(x => x.link === pathnames[pathnames.length - 1]);
    setIndex(index);
    let linkItem = links[index];
    if (!param && !!linkItem)
      linkItem.url = "survey";

    setSelected(!page ? linkItem : json.links.find(x => x.url === page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc])

  const getCategory = async () => {
    await ApiRequest(`category/keyword/${key}`).then(res => {
      if (res.status === 200) {
        document.title = res.data?.name;
        setCategory(res.data);
      }
    }, error => {
      ErrorHandle(error, navigate);
    });
  }

  const navigation = (url) => {
    navigate(`/categories/${key}/${url}`);
  }

  const expandHandle = () => {
    setExpanded(!expanded)
  }

  const removeCategory = async () => {
    await ApiRequest(`category/${category?.id}`, "delete").then(res => {
      if (res.data === null)
        navigate("/");
      else
        setResponse(res.data);
    }, error => ErrorHandle(error, navigate))
  }

  const props = path !== undefined ? { item: selected, url: `/categories/${key}/survey`, update, param, category, expandHandle, navigate, getCategory }
    : { item: selected, url: loc.pathname, update, navigate };

  return (
    <div className='section-container d-row jc-between'>

      {/* Menu */}
      <div className={'section-left-block d-column jc-start' + ((expanded || !key) ? " hidden" : "")}>
        <h2 className='section-category d-row jc-start'>
          <Category /> {category?.name}
        </h2>

        {/* Menu  */}
        <ListBox className='section-menu'>
          {links?.map((link, ind) => {
            return <ListItem key={ind}
              className={`d-row jc-between${link?.class ?? ""}${index === ind ? " active-link" : ""}`}
              style={{ marginTop: !link?.name ? "40px" : "inherit" }}
              onClick={() => navigation(link.url)} secondaryAction={(!!link?.count && !!category) ? ((category[link.count] > 0) ? category[link.count] : "0") : ""}>
              <ListItemAvatar>
                <Avatar>{!link?.name ? <List /> : (ind !== index ? <ArrowForward /> : <ArrowDownward />)}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={link.label} />
            </ListItem>
          })}
        </ListBox>

        {/* Category actions buttons */}
        <div className='slb-buttons d-row ai-end'>
          <Button variant='outlined' onClick={() => navigate(`/edit/category/${category?.id}`)}>  <Edit /> </Button>
          <Button variant='outlined' onClick={removeCategory}>
            <Delete />
          </Button>
        </div>
      </div>

      {/* Content */}
      <div className='section-right-block'>
        <div className='block-content-wrapper'>
          <Outlet context={props} />
        </div>
      </div>

      {/* Response model */}
      {!!response && <Modal
        error={true}
        content={response?.msg}
        close={() => setResponse()} />}
    </div>
  )
}

export default Section;