import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Installed
import { Box, Button, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';

// Components
import LinearLoading from '../../components/LinearLoading';
import FormBlock from '../../components/FormBlock';
import FormPost from '../../components/FormPost';
import Logotype from '../../components/Logotype';
import Response from '../../components/Response';
import Countdown from '../../components/Countdown';

// Functions
import { ErrorHandle } from '../../functions/ErrorHandle';

// Service
import ApiRequest from '../../services/ApiRequest';

function Survey({ authorized }) {
    Survey.displayName = "Survey";

    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [resendModel, setResendModel] = useState(null);
    const [resendId, setResendId] = useState(null);
    const [form, setForm] = useState(null);
    const [response, setResponse] = useState(null);
    const [lastTime, setLastTime] = useState();

    const { modelId, receiverId, resend } = useParams();

    const navigate = useNavigate();
    const loc = useLocation();

    useEffect(() => {
        if (!modelId || !receiverId) return;

        if (!!resend && resend === "resend")
            getCompletedSurvey();
        else
            getSurvey();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelId, receiverId, resend])

    const getSurvey = async () => {
        await ApiRequest(`survey/model/${modelId}/${receiverId}`).then(res => {
            const { model, lastTime, responseId, saved } = res.data;
            if (saved)
                getCompletedSurvey(true);
            else {
                setLoading(false);
                if (!model) {
                    setLastTime(lastTime);
                    setResendId(responseId);
                    setResponse(res.data);
                    document.title = "Enkät är inte tillgänglig";
                } else {
                    setModel(model);
                    handleForm(model);
                }
            }
        }, error => {
            setResponse(ErrorHandle(error, navigate));
            setLoading(false);
        });
    }

    const getCompletedSurvey = async (saved = false) => {
        setLoading(true);
        setResponse(null);

        await ApiRequest(`survey/completed/${modelId}/${receiverId}/${saved}`).then(res => {
            setLoading(false);
            const { model, response } = res.data;

            if (!model || !response)
                setResponse(res.data);
            else {
                setModel(model);
                handleForm(model);
                setResendId(response.id);
                setResendModel(JSON.parse(response?.resendValue));
            }
        }, error => {
            setResponse(ErrorHandle(error, navigate));
            setLoading(false);
        });
    }

    const resendSurvey = (resend) => {
        let url = `/customer/survey/open/${modelId}/${receiverId}/resend`;
        if (!resend)
            navigate(0);
        else if (loc.pathname === url){
            navigate(0);
            // navigate(`${url}/reload`, { replace: true });
        } else {
            setTimeout(() => {
                navigate(url);
            }, 100)
        }
    }

    const handleForm = (model) => {
        setLastTime(model.deadlineSeconds);
        document.title = model?.form?.name;
        setForm({
            id: !!resend ? resendId : null,
            name: model?.form?.name,
            participantId: receiverId,
            participant: !!resend ? model?.participant : model?.email,
            surveyId: model?.surveyId,
            resendValue: "",
            responseJson: ""
        })
    }

    const reload = () => {
        setLoading(true);
        getSurvey();
    }

    const reset = () => {
        if (["error", "warning"].indexOf(response?.res) === -1) {
            window.opener = null;
            window.open("", "_self");
            window.close();
        } else
            setResponse();
    }

    return (
        <div className={`${loading ? 'view-form-loading' : "view-survey fade-in"} w-100`}>

            {/*  Logotype */}
            <Box className="vs-box d-column ai-end w-100">
                <Logotype css="vs-logo" />
                {typeof lastTime === "number" && <Tooltip title="Enkäten stängningstid" arrow classes={{
                    tooltip: "tooltip-white",
                    arrow: "tooltip-arrow-white"
                }} >
                    <p className='vs-deadline'><span>Återstående tid:</span> <Countdown deadline={lastTime} reload={reload} /></p>
                </Tooltip>}
            </Box>

            {/* Loading */}
            {loading && <LinearLoading msg="enkät laddas upp ..." color="success"
                size={30} styles={authorized ? null : { height: "100vh" }} />}

            {/* Content */}
            {(!response && !!model) && <FormBlock form={model?.form}>
                <p><span>Leverantör:</span>{model?.supplier?.name}</p>
                <p><span>Deadline:</span>{model.viewDeadline}</p>
            </FormBlock>}

            {/* Form */}
            {(!!model?.inputs && !response) && <FormPost
                api={`survey/reply${(resend ? `/${resendId}` : "")}`}
                confirmation={true}
                inputs={model?.inputs}
                resendModel={resendModel}
                numbers={true}
                button={<Tooltip title="Spara mina svar att fortsätta senare." classes={{
                    tooltip: "tooltip-info",
                    arrow: "tooltip-arrow-info"
                }} arrow>
                    {!resend && <Button name="save" variant="contained" color="primary" type="submit">
                        Spara svar
                    </Button>}
                </Tooltip>}
                model={form}
                handleResponse={(value) => setResponse(value)} />}

            {/* If survey already is done */}
            {!!response && <Response res={response} reset={reset}>
                {(response?.editable || response?.saved) &&
                    <Button className='survey-edit' color={response?.color ?? "success"} startIcon={<Edit fontSize='small' />}
                        onClick={() => resendSurvey(response?.editable)}>
                        {response?.editable ? "Redigera" : "Forstätta svara"} </Button>}
            </Response>}
        </div>
    )
}

export default Survey;