
import { useContext, useEffect, useState } from 'react';

// Installed
import { Button, Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@mui/icons-material';

// Components
import Response from '../../components/Response';

// Functions
import { DecodedToken } from '../../functions/DecodedToken';
import { ErrorHandle } from '../../functions/ErrorHandle';

// Services
import ApiRequest from '../../services/ApiRequest';
import { AuthContext } from '../../services/AuthContext';

// Storage
import { config } from '../../storage/Config';


// Css
import "../../assets/css/login.css";

function Login() {
  Login.displayName = "Login";

  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(!!sessionStorage.getItem("login"));
  const [remember, setRemember] = useState(false);

  const { instance, accounts } = useMsal();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const isAuthorized = useIsAuthenticated();

  useEffect(() => {
    if (!!sessionStorage.getItem("response"))
      setResponse(JSON.parse(sessionStorage.getItem("response")));
  }, [])

  useEffect(() => {
    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized])


  // Check user authentication
  const authenticate = async () => {
    // Login automate if a valid active token is present
    if (isAuthorized) {
      const loginRequest = {
        scopes: config.auth.scopes
      }
      const request = {
        ...loginRequest, account: accounts[0]
        // ...loginRequest, prompt: 'login'
      };

      await instance.acquireTokenSilent(request).then((response) => {
        // loginActions(response.account);
        loginActions(DecodedToken(response.accessToken));
      }, () => {
        // instance.acquireTokenPopup(request).then((response) => {
        instance.acquireTokenRedirect(request).then((response) => {
          loginActions(DecodedToken(response.accessToken));
        });
      });
    }
  }

  const handleLogin = () => {
    setLoading(true);
    if (remember)
      sessionStorage.setItem("remember", true);
    sessionStorage.setItem("login", true);

    cleanResponse();

    setLoading(true);
    const loginRequest = {
      scopes: config.auth.scopes
    }

    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  // Save toke if user is authorized
  const loginActions = async (model) => {

    if (!model) return;
    setLoading(true);

    var userData = {
      name: model?.name,
      username: model?.upn,
      groups: model.groups,
      remember: !!sessionStorage.getItem("remember")
    }

    await ApiRequest("authentication", "post", userData).then(
      (res) => {
        const { remember, token, msg } = res?.data;
        setLoading(false);

        if (!!msg) {
          sessionStorage.setItem("response", JSON.stringify(res.data))
          setResponse(res.data);
        } else {
          if (remember) {
            localStorage.setItem("token", token);
          } else
            sessionStorage.setItem("token", token);

          if (token)
            authContext.authorize(token);

          sessionStorage.removeItem("remember");
          sessionStorage.removeItem("login");
          setLogin(false);
          navigate("/");
        }
      },
      (error) => {
        setLoading(false);
        setResponse(ErrorHandle(error));
      }
    );
  }


  function cleanResponse() {
    localStorage.removeItem("response");
    setResponse();
  }


  /* Loading */
  if (login)
    return null;

  return <div className='d-row w100 login-container'>
    <div className="d-column jc-between ai-start login-wrapper">
      <h2>Leverantörsuppföljning</h2>

      {/* Form */}
      {!response && <div className='d-row login-form jc-between w-100'>
        <Button type="submit" className="login-button" onClick={handleLogin} color='inherit' disabled={loading}>
          {loading ? <CircularProgress size={23} /> : "Logga in"}
        </Button>
        <FormControlLabel className="d-row" disabled={loading} control={
          <Checkbox id="checkbox" checked={remember} name="remember"
            aria-checked color={loading ? "default" : "primary"}
            onChange={() => setRemember(!remember)} icon={<CheckBoxOutlineBlankOutlined />} checkedIcon={<CheckBoxOutlined />} />
        } label={<span title="30 dagar">Kom ihåg mig</span>} />
      </div>}


      {/* Response */}
      {!!response && <Response res={response} cancel={cleanResponse} />}
    </div>
  </div>
}

export default Login;