import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Installed
import { ArrowDownward, ArrowUpward, CheckBox, CheckBoxOutlineBlank, Delete, Edit, Numbers } from "@mui/icons-material";
import {
    Button, Checkbox, FormControlLabel, Tooltip, IconButton, Alert, AlertTitle, Select, MenuItem
} from "@mui/material";

// Components
import FormButtons from "./FormButtons";
import Modal from "./Modal";
import FormFields from "./FormFields";

// Services
import ApiRequest from './../services/ApiRequest';

// Functions
import { ErrorHandle } from "../functions/ErrorHandle";

function FormManage({ inputs, id, categoryId, sort, updateForm, handleEdit, close,
    changed, visible, permission, mode, saveChange, closeModal }) {
    FormManage.displayName = "FormManage";

    const navigate = useNavigate();
    const { used } = useParams();
    const refWarning = useRef(null);
    const refIndexes = useRef([]);
    const refForm = useRef(null);

    const [swapIndex, setSwapIndex] = useState(null);
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hidden, setHidden] = useState(mode);
    const [warning, setWarning] = useState(!!used);
    const [confirm, setConfirm] = useState(false);
    const [scrollIndex, setScrollIndex] = useState(parseInt(sessionStorage.getItem("scrollIndex")));

    useEffect(() => {
        if (changed && warning) {
            setTimeout(() => {
                refWarning?.current.scrollIntoView();
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changed, warning])

    useEffect(() => {
        if (!!response) setLoading(false);
    }, [response])

    useEffect(() => {
        if (!!scrollIndex && scrollIndex > -1) {
            refIndexes?.current[scrollIndex]?.scrollIntoView();
            setTimeout(() => {
                setScrollIndex(null);
            }, 100)
        }
    }, [inputs, scrollIndex])


    useEffect(() => {
        if (!!sort && (parseInt(sort) || sort?.length > 3)) {
            const index = inputs.findIndex((x, index) => (x?.label || x?.name || x?.type)?.toLowerCase()?.includes(sort) || (index - 1)?.toString() === sort);
            if (!!index && index > -1) {
                setTimeout(() => {
                    setScrollIndex(index);
                }, 0)
            }
        } else
            refForm?.current?.scrollIntoView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort])

    const handleSwapIndex = (e) => {
        const index = parseInt(e.target.value);
        if (!swapIndex)
            setSwapIndex(index);
        else if (swapIndex === index)
            setSwapIndex(null);
        else
            setSwapIndex(index);
    }

    const switchPosition = (index, scroll = false) => {
        let arr = inputs;

        let swIndex = swapIndex;
        if (index > swapIndex) {
            for (var i = swIndex; i <= index; i++) {
                arr[i] = arr.splice(swIndex, 1, arr[i])[0];
                swIndex = i;
                setSwapIndex(i);
            }
        } else {
            for (var x = swapIndex; x >= index; x--) {
                arr[x] = arr.splice(swIndex, 1, arr[x])[0];
                swIndex = x;
                setSwapIndex(x);
            }
        }

        if (scroll) {
            sessionStorage.setItem("scrollIndex", index);
            setSwapIndex(null);
        }

        updateForm(arr);
    }

    const removeItem = (index) => {
        const updatedInputs = inputs.filter((x, ind) => ind !== index);
        updateForm(updatedInputs);
    }

    const hiddenChange = () => {
        setHidden(!hidden);
        updateForm(!hidden);
    }

    const onSubmit = async (value) => {

        if (!!id && !changed) {
            navigate(`/send/survey/${id}`);
            return;
        }

        setLoading(true);

        const listInputs = inputs.filter(x => !!x?.name);
        if (listInputs.length === 0) {
            setResponse({ msg: "Formulär måste innehålla minst ett fråga fält.", error: true });
            return;
        }

        listInputs.map((item) => {
            delete item.item;
            delete item.img;
            delete item?.placeholder;
            delete item?.variantsList;
            delete item?.slideTypes;

            if (item?.missed !== undefined) {
                if (item?.missed) {
                    if (item?.type === "name")
                        item.label = null;
                    else
                        item.value = null;
                }
                delete item?.missed;
            }

            if (item?.color !== undefined)
                delete item?.color;

            return item;
        })

        const obj = {
            id: id ?? null,
            name: inputs.find(x => x.item === "Title")?.label ?? null,
            description: inputs.find(x => x.item === "Beskrivning")?.value ?? null,
            categoryId: categoryId,
            inputs: listInputs,
            hidden: hidden
        }

        if (!obj?.name || obj?.name === "") {
            setResponse({ msg: "Titel saknas", error: true });
            return;
        }

        setSwapIndex(null);

        await ApiRequest((!id ? "form" : `form/${id}`), (!id ? "post" : "put"), obj).then(res => {
            setLoading(false);

            if (typeof value === "boolean" && res?.status === 200)
                navigate(`/send/survey/${res.data?.id}`);
            else
                setResponse(res.data);
        }, error => {
            setResponse(ErrorHandle(error, navigate));
            setLoading(false);
        })
    }

    const deleteItem = async () => {
        if (!confirm) return;
        setLoading(true);

        await ApiRequest(`form/${id}`, "delete").then(res => {
            if (res.data === null)
                navigate(-1);

            setLoading(false);
        }, error => {
            setResponse(ErrorHandle(error, navigate));
            setLoading(false);
        })
    }

    const handleClose = () => {
        if (confirm)
            setConfirm();
        else {
            saveChange();
            setResponse(null);
            navigate(-1);
        }
    }

    const closeResponseModal = () => {
        if (confirm)
            deleteItem();
        else {
            if (!response?.error)
                closeModal();
            setResponse();
        }
    }

    return (
        <div className="form-building-overview d-column jc-start ai-start fade-in" ref={refForm}>

            {/* Warning/Info alert */}
            {(warning && changed) && <div className="form-control">
                <Alert variant="standard" severity="warning" color="warning" onClose={() => setWarning(false)} ref={refWarning}>
                    <AlertTitle>Observera!</AlertTitle>
                    <span dangerouslySetInnerHTML={{ __html: "Enkäten har skickats en eller flera gånger.<br/>Om du ändrar formuläret sparas det som en ny enkät." }}></span>
                </Alert></div>}

            {/* Loop of all inputs */}
            {inputs.map((item, index) => {

                const actionsButtons = <>
                    <IconButton onClick={() => {
                        handleEdit(index);
                        sessionStorage.setItem("scrollIndex", index)
                    }}>
                        <Edit color={parseInt(scrollIndex) === index ? "disabled" : "primary"} />
                    </IconButton>

                    {item?.item !== "Title" && <IconButton onClick={() => removeItem(index)}>
                        <Delete color="error" />
                    </IconButton>}
                </>;

                if (!item?.name)
                    return <div className="form-control" key={index}>

                        {item?.label !== undefined && <h2 className={`${!item?.label ? "missed-label" : ""}`}>{item?.label ?? item?.missed}</h2>}

                        {item?.value !== undefined && <p className={`fbo-description w-100${!item?.value ? " missed-description" : ""}`} dangerouslySetInnerHTML={{ __html: item?.value ?? item?.missed }}></p>}

                        {/* Edit, remove item from form */}
                        <div className="item-actions d-row jc-end w-100">{actionsButtons}</div>
                    </div>;

                return <div ref={ref => refIndexes.current[index] = ref} key={index}>
                    <FormFields inputs={inputs.filter((x, i) => i === index)}
                        initData={{ [item?.name]: (item?.type === "checkbox") ? false : "" }}
                        visible={visible} keyIndex={index} scrollIndex={scrollIndex}>

                        {/* Edit actions menu */}
                        <div className={`item-actions d-row jc-end`}>

                            {/* Number of question */}
                            <label className="d-row w100">
                                {/* <Numbers /> {!!sortIndex ? sortIndex : (index - 1)} */}
                                <Numbers /> {index - 1}
                            </label>

                            {/* Set new position for index */}
                            {!!swapIndex && index === swapIndex && <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={swapIndex - 1}
                                autoWidth
                                style={{ height: "80%", marginBottom: 0, backgroundColor: "#FFFFFF" }}
                            >
                                {inputs.filter(x => !!x.name)?.map((i, ind) => {
                                    return <MenuItem onClick={() => switchPosition(inputs.findIndex(x => x.label === i.label), true)} key={ind} value={ind + 1}>{ind + 1}</MenuItem>
                                })}
                            </Select>}

                            {/* Swap up/down */}
                            {(!!swapIndex && swapIndex !== index) && <IconButton onClick={() => switchPosition(index)}>
                                {swapIndex < index ? <ArrowDownward color="error" /> : <ArrowUpward color="primary" />}
                            </IconButton>}

                            {/* Set index to wrap */}
                            <FormControlLabel control={<Checkbox
                                value={index}
                                checked={!!swapIndex && index === swapIndex}
                                onChange={handleSwapIndex}
                            />} />

                            {/* Edit, remove item from form */}
                            {actionsButtons}
                        </div>
                    </FormFields>
                </div>;
            })}

            {/* Actions buttons  */}
            <FormButtons
                reset={close}
                confirmation={true}
                value="Spara"
                position="sticky"
                loading={loading}
                disabled={loading || (!!id && !changed)}
                send={onSubmit}>

                {/* Choice is a developing mode */}
                {!!permission && <Tooltip title="Spara för utveckling. Ej synlig för andra användare."
                    classes={{
                        tooltip: "tooltip-white",
                        arrow: "tooltip-arrow-white"
                    }} arrow>
                    <Button className="checkbox-mode"
                        startIcon={hidden ? <CheckBox /> : <CheckBoxOutlineBlank />}
                        onClick={hiddenChange}
                    >
                        Test Artikel
                    </Button>
                </Tooltip>}

                <Tooltip title="Dubbelklicka att radera formulär" classes={{
                    tooltip: "tooltip-red",
                    arrow: "tooltip-arrow-red"
                }} disableHoverListener={loading} arrow>
                    <span>
                        <Button variant="outlined" color="error" disabled={loading} onDoubleClick={() => setConfirm(true)} >
                            <Delete />
                        </Button>
                    </span>
                </Tooltip>

                <Button variant="outlined"
                    color="info"
                    disabled={loading || (!!id && !changed)}
                    onClick={() => onSubmit(true)}>
                    Skicka & Spara
                </Button>
            </FormButtons>

            {/* Modal response */}
            {(!!response || confirm) && <Modal
                buttonValue={!confirm ? "Ja" : "Nej"}
                closeButtonValue={confirm ? "Ja" : "Nej"}
                color="primary"
                error={response?.error}
                msg={confirm ? (inputs[0]?.label ?? "Bekräfta") : null}
                content={confirm ? `Radera permanent?` : (response?.msg ?? "Vill du spara den som utkast?")}
                clickHandle={handleClose}
                close={closeResponseModal} />}

        </div>
    )
}

export default FormManage;