
import { useEffect, useState } from "react";

// Installed
import { Button, CircularProgress } from "@mui/material";
import { Close } from "@mui/icons-material";


function FormButtons({ children, confirmation, disabled, position, value, loading, send, reset }) {
    FormButtons.displayName = "FormButtons";

    const [confirm, setConfirm] = useState(false);
    const [delay, setDelay] = useState(true);

    useEffect(() => {
        if (loading) setConfirm(false);
    }, [loading])


    const confirmHandle = () => {
        setConfirm(!confirm);
        setTimeout(() => {
            setDelay(confirm);
        }, 100)
    }

    const divClass = `form-buttons d-row jc-end ${!!position ? `position-${position}` : ""}`;

    /* If confirmation is not required or action already confirmed */
    const props = {
        ...{
            variant: "outlined",
            className: "submit-btn",
            color: loading ? "primary" : "inherit",
            disabled: disabled || loading,
            type: confirmation ? "button" : "submit"
        }, ...(confirmation ? { onClick: confirmHandle } : {})
    }

    const confirmProps = !!send ? { onClick: send, type: "button" } : { type: "submit" };


    // Confirm block
    if (confirm) {
        return (
            <div className={divClass}>
                <p className="confirm-question">{`${value ?? "Skicka"}?`}</p>
                <Button variant="outlined"
                    className="bg-white"
                    onClick={confirmHandle}
                    color="inherit">Nej</Button>

                <Button variant="contained" className="bg-unset" {...confirmProps}
                    disabled={delay}
                    color="error">Ja</Button>
            </div>
        );
    }


    return (
        <div className={divClass}>

            {!!reset && <Button variant="contained" color="error" disabled={loading || disabled} onClick={reset} >
                <Close color="inherit" fontSize="medium" />
            </Button>}

            {/* Children block */}
            {(children && !disabled) && children}

            <Button {...props}>
                {loading ? <CircularProgress size={18} color="inherit" /> : value ?? "Skicka"}
            </Button>
        </div>
    )
}

export default FormButtons;