
import { useContext, useEffect, useState } from "react";

// Installed
import { List, ListItem, ListItemIcon, ListItemText, Switch } from "@mui/material";
import { ListOutlined } from "@mui/icons-material";

// Components
import Container from "../../components/Container";
import Loading from "../../components/Loading";
import Response from "../../components/Response";

// Json
import json from "../../assets/json/inputs.json";

// Functions
import { ErrorHandle } from "../../functions/ErrorHandle";

// Services
import ApiRequest from "../../services/ApiRequest";
import { AuthContext } from "../../services/AuthContext";


function SupportTools({ api, request, label, icon }) {
    SupportTools.displayName = "SupportTools";

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState();

    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (!request)
            setList(json.supportLinks);

        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    async function switchHandle(api) {
        const active = authContext.workStatus;
        await ApiRequest(`${api}/${active}`).then(res => {
            authContext.updateServiceWorkStatus(res.data);
        }, error => setResponse(ErrorHandle(error)))
    }

    return (
        <div className="view-form-wrapper view-wrapper fade-in w-100">
            {/* Loading */}
            {loading && <Loading msg="data hämtas ..." color="success" size={30} />}

            {/* Content */}
            {!loading && <Container
                label={label ?? "Fel"}>


                {/* Response */}
                {!!response && <Response res={response} reset={() => setResponse()} />}

                {/* Data list */}
                {!!list && <List className="w100 fade-in box-list">
                    {list?.map((item, ind) => {
                        return <ListItem className='d-row jc-start box-list-li-border' key={ind} secondaryAction={
                            <Switch checked={authContext.workStatus} onChange={() => switchHandle(item.api)} />
                        }>
                            <ListItemIcon>
                                {icon ?? <ListOutlined />}
                            </ListItemIcon>
                            <ListItemText primary={item?.label || item?.name} />
                        </ListItem>
                    })}
                </List>}
            </Container>}
        </div>
    )
}

export default SupportTools;