
import React, { useState } from 'react';

// Installed
import { Alert, CircularProgress, Collapse, FormGroup, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, Delete, Error, ExpandLess, ExpandMore, ListOutlined } from '@mui/icons-material';

// Components
import Modal from './Modal';

// Services
import ApiRequest from '../services/ApiRequest';

// Functions
import { ErrorHandle } from '../functions/ErrorHandle';

function DropdownList({ label, name, list, icon, selected, checked, dropdown,
    removable, removeDisabled, api, handleDropdown, error, onClickHandle }) {

    const [open, setOpen] = useState(dropdown);
    const [confirmItem, setConfirmItem] = useState(null);
    const [items, setItems] = useState(list);
    const [loading, setLoading] = useState(false);

    const clickHandle = (item) => {
        if (!dropdown)
            handleOpen();

        if (!onClickHandle) return;

        if (!!item?.iParams && item?.iParams?.pointsVariants) {
            const arr = [];
            item?.iParams?.pointsVariants.forEach(p => {
                arr.push(p.label);
            })
            item.iParams.variants = arr;
            delete item?.iParams?.pointsVariants;
        }
        onClickHandle(item);
    }

    const handleOpen = () => {
        setOpen(!open);
        if (!!handleDropdown)
            handleDropdown(!open);
    }

    const removeItem = async () => {
        const keyword = confirmItem;
        setConfirmItem(null);
        setLoading(true);
        await ApiRequest(`${api}/${keyword}`, "delete").then(res => {
            setLoading(false);
            if (res.data === null)
                setItems(items.filter(x => x !== keyword));
            else
                ErrorHandle(res.data);
        }, error => {
            setLoading(false);
            ErrorHandle(error);
        });
    }

    return (<>
        <FormGroup className={`dropdown-list${!!error ? " error" : ""}`}>
            <ListItemButton onClick={handleOpen}>
                <ListItemIcon>
                    {icon ?? <ListOutlined />}
                </ListItemIcon>
                <ListItemText primary={label} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} className='d-row' timeout="auto" unmountOnExit>
                <List sx={{ pl: 2, backgroundColor: "#FFFFFF", height: "max-content", maxHeight: "300px", overflow: "auto" }}>
                    {/*  If items is not null */}
                    {items.length > 0 && items?.map((item, index) => {
                        return <ListItem key={index} className="d-row"
                            secondaryAction={(removable && !error) ?
                                <IconButton onClick={() => setConfirmItem(item[name] ?? item)} disabled={removeDisabled}>
                                    {loading ? <CircularProgress color="error" size={20} /> : <Delete color="error" />}
                                </IconButton> : (error ? <Error color="error" /> : "")}>
                            <ListItemButton onClick={() => clickHandle(item)}>
                                <ListItemIcon>
                                    {selected?.indexOf(!!checked ? item[checked] : item) > -1 ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank />}
                                </ListItemIcon>
                                <ListItemText primary={item[name] ?? item} style={{ flexGrow: 2 }} />
                            </ListItemButton>
                        </ListItem>
                    })}

                    {/* If item doesn't exists */}
                    {items.length === 0 && <Alert severity='info' color='info' variant='standard'>
                        - Inga {label.toLowerCase()} finns att visa
                    </Alert>}
                </List>
            </Collapse>
        </FormGroup>

        {/* Modal */}
        {!!confirmItem && <Modal
            buttonValue="Ja"
            msg={`Ta bort?`}
            content={confirmItem}
            closeButtonValue="Avbryta"
            clickHandle={removeItem}
            close={() => setConfirmItem(null)} />}
    </>
    )
}

export default DropdownList;