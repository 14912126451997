// Installed
import { Alert } from "@mui/material";

function Response({ children, res, reset }) {

    const str = typeof res === "string";
    const msg = str ? str : res?.msg;
    const color = res?.color ?? "success";

    return <div className={`response-box d-column ai-end w-100 ${color}`}>

        {/* Message */}
        <Alert color={color} variant="standard" severity={color} onClose={reset}>
            <p className="response-message" dangerouslySetInnerHTML={{ __html: msg ?? "Skickad!" }}></p>
        </Alert>

        {!!children && children}
    </div>
}

export default Response;