import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// Installed
import { Add, ArrowBack, Close, CloseFullscreen, OpenInFull, Refresh, Search } from "@mui/icons-material";
import { IconButton, TextField, Tooltip } from "@mui/material";

// Css
import "./../assets/css/container.css";

function Container({ children, buttons, label, sort, url, hidden, update, expands, sorting, expand, pathname }) {
    Container.displayName = "Container";

    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const refExpandButton = useRef();

    useEffect(() => {

        if (!expands) return;

        setTimeout(() => {
            if (!!expands)
                refExpandButton?.current.click();
        }, 100)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expands])

    const expandHandle = () => {
        setExpanded(!expanded);
        if (!!expand)
            expand();
    }

    return <>
        <div className='block-header d-row jc-between w-100'>
            <h3 dangerouslySetInnerHTML={{ __html: label }}></h3>

            {/* Buttons */}
            <div className='actions-wrapper d-row'>
                
                {/* Sorting input */}
                {!!sorting && <div className='search-wrapper'>
                    <TextField
                        className='search-input'
                        name="sort"
                        type="text"
                        value={sort}
                        onChange={(e) => sorting(e.target?.value)}
                        inputProps={{
                            maxLength: 15
                        }}
                    />
                    <IconButton disabled={sort?.length === 0} onClick={() => sorting("")}>
                        {sort.length > 0 ? <Close color="error" /> : <Search />}
                    </IconButton>
                </div>}

                {/* Button from parent component */}
                {!!buttons && buttons}

                {/* Add button */}
                {!hidden && <IconButton onClick={() => navigate(`${url}/new/form`)}>
                    <Add color="inherit" />
                </IconButton>}

                {/* Update button */}
                {!!update && <Tooltip title="Updatera" classes={{
                    tooltip: "tooltip-default",
                    arrow: "tooltip-arrow-default"
                }} arrow>
                    <IconButton onClick={update}>
                        <Refresh color="inherit" />
                    </IconButton>
                </Tooltip>}

                {/* Expand window */}
                {!!expand && <IconButton onClick={expandHandle} ref={refExpandButton}>
                    {expanded ? <CloseFullscreen color="error" /> : <OpenInFull />}
                </IconButton>}

                {/* Go back */}
                <IconButton onClick={() => navigate(pathname ? pathname : -1)}>
                    <ArrowBack />
                </IconButton>

            </div>
        </div>

        {/* Child content */}
        {children && children}
    </>
}

export default Container;