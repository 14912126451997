

import { useContext, useEffect, useState } from 'react';

// Services
import AuthContextProvider, { AuthContext } from './services/AuthContext';

// Routes
import AppRoutes from './routes/AppRoutes';
import AuthRoutes from './routes/AuthRoutes';

// Css
import './assets/css/custom.css';

export default function App() {
  App.displayName = "App";

  return <AuthContextProvider>
    <Root />
  </AuthContextProvider>
}

function Root() {

  const [loading, setLoading] = useState(true);

  const authContext = useContext(AuthContext);

  useEffect(() => {

    localStorage.removeItem("devTeam");
    const token = localStorage.getItem("token") || sessionStorage.getItem("token");
    if (!!token)
      authContext.authorize(token);
    else
      authContext.logout();

    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if(loading) return null;

  return (
    !authContext.isAuthorized ? <AppRoutes /> : <AuthRoutes />
  );
}